<template>
  <div class="mx-5 py-5" style="text-align: left !important">
    <div class="row">
        <div class="col-12 mt-2">
            <label class="title">Upload Payments</label>
        </div>
        <div class="text-right">
            <button class="btn btn-secondary" type="button" @click="reloadPage">Reset</button>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <label class="form-label" for="data">Denomination <span class="text-danger">*</span></label>
            <v-select :options="denominations" label="name" class="vselect w-100" placeholder="Choose Denomination" v-model="denominationId" :reduce="denominationId => denominationId.id">
                <template slot="option" slot-scope="option">
                    <span class="pl-4">{{ option.id + " - " + option.name }}</span>
                </template>
            </v-select>
        </div>
        <div class="col-4">
            <div class="mb-3">
                <label class="form-label" for="data">Payments <span class="text-danger">*</span></label>
                <input class="form-control" type="file" id="data" v-on:change="(e) => onExcelChange(e)">
            </div>
        </div>
        <div class="col-4" style="margin: auto 0;">
            <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="deleteOld" v-model="deleteOldPayments">
                <label class="form-check-label" for="deleteOld"> Delete Old Payments<span class="text-danger"> *</span></label>
            </div>
        </div>
    </div>
    <div class="row" v-if="excelData != null">
        <div class="col-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th class="text-capitalize" v-for="(header, index) in headers" :key="index">{{header}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(col, index) in excelData" :key="index">
                        <td v-for="(header, index2) in headers" :key="index2">{{col[header]}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" v-if="excelData != null">
        <div class="col-12 text-end">
            <button type="button" class="btn btn-primary ms-3" @click="submitButton">Submit</button>
        </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx/xlsx";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
    components:{
        vSelect,
    },
    data(){
        return{
            checkIfEmpty: commonFunctions.checkIfEmpty,
            excelData: null,
            headers: null,
            denominationId: null,
            denominations: [],
            rquestData: null,
            deleteOldPayments: false,
        }
    },
    created(){
        this.getDenominations("created", true)
    },
    methods: {
        onExcelChange(e) {
            this.$store.state.loader.loading = true;
            const files = e.target.files;
            if (!files.length) {
                return;
            } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
                this.$store.state.loader.loading = false;
                this.$toast.error("File Format Is Invalid! Please Provide xlsx File");
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                    const data = ev.target.result;
                    const workbook = XLSX.read(data, {
                        type: "binary"
                    });
                    const wsname = workbook.SheetNames[0]; 
                    const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); 
                    this.excelData = ws
                    const a = workbook.Sheets[workbook.SheetNames[0]];
                    this.headers = this.getHeader(a);
                    this.handleRequest(ws)
                    this.$store.state.loader.loading = false;
                } catch (e) {
                    this.$toast.error("Read failure!")
                    this.$store.state.loader.loading = false;
                    return;
                }
            };
            fileReader.readAsBinaryString(files[0]);
        },
        getHeader(sheet) {
            const headers = [];
            const range = XLSX.utils.decode_range(sheet["!ref"]); 
            let C;
            const R = range.s.r; 
            let i = 0;
            for (C = range.s.c; C <= range.e.c; ++C) {
                var cell =
                    sheet[
                    XLSX.utils.encode_cell({ c: C, r: R })
                    ];
                var hdr = "UNKNOWN" + C; 
                if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
                if (hdr.indexOf('UNKNOWN') > -1) {
                    if (!i) {
                        hdr = '__EMPTY';
                    } else {
                        hdr = '__EMPTY_' + i;
                    }
                    i++;
                }
                headers.push(hdr);
            }
            return headers;
        },
        handleRequest(data) {
            var request = [];
            for (var i = 0; i < data.length; i++) {
                var keys = Object.keys(data.at(i));
                var row = []
                for (var j = 0; j < keys.length; j++) {
                    row.push({
                        "key": keys.at(j),
                        "value": data.at(i)[keys.at(j)]
                    })
                }
                request.push({"paymentsBeans": row})
            }
            this.rquestData = request
            
        },
        getDenominations(source, forceGet) {
            if (source == 'created' && (forceGet || this.denominations == null || this.denominations.length == 0)) {
                Http()
                    .get(ENDPOINTS.userDenominations, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.denominations = response.data;
                            if(response.data.length == 1){
                                this.denominationId = response.data.at(0).id
                            }
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    })
                    .catch((e) => {
                        setTimeout(this.getDenominations(source, true), 500);
                    });
            }
            return this.denominations;
        },
        submitButton(){
            if(this.checkIfEmpty(this.denominationId)){
                this.$toast.error("Denomination Is Invalid")
                return;
            }
            if(this.checkIfEmpty(this.rquestData)){
                this.$toast.error("Excel Data Is Invalid")
                return;
            }
            Http()
                .post(ENDPOINTS.uploadPayments, {
                    denominationId: this.denominationId,
                    payments: this.rquestData,
                    deleteOldPayments: this.deleteOldPayments
                })
                .then((response) => {
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        reloadPage() {
            window.location.reload();
        },
    },
}
</script>


<style scoped>
.title {
    font-size: 30px;
    font-weight: bold;
}

.subTitle {
    font-size: 23px;
    font-weight: 500;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
.btn-primary{
    background-color: #E40046!important;
  }
</style>