<template>
  <div class="">
    <div class=" fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-50 m-auto" v-if="!loading && !error" >
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              Multi-Factor Authentication Setup
            </h3>
            <button type="button"
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="$emit('close')">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <div class="m-auto py-5">
            <VueQRCodeComponent :text='qrValue'></VueQRCodeComponent>
          </div>
          <div class="p-5">
            <p class="float-left text-left">
              <span><strong>Step 1: </strong>Scan the QR code above, or enter the secret below in the Google Authenticator App.</span>
              <span v-if="secretShown">
                <br/>
                <strong>Secret: </strong>{{secret}}
              </span>
              <br/>
              <span><strong>Step 2: </strong> Once you've scanned the QR code or entered the secret, complete the set up by entering the code listed in your Google Authenticator App.</span>
            </p>
            <input spellcheck="false" v-model="otp" v-focus style="font-size: 150%"
              class="text-4xl font-bold text-transform-none w-full h-12 mt-2 mb-1 bg-gray-light rounded p-3 focus:outline-none focus:bg-white text-black focus:border-blue-800 border-transparent border-2 border-black" />
              <button class="bg-woo  text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-sm text-sm float-right mt-3" @click="verifyAuthenticatorOtp">Verify</button>
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>

import Http, { ENDPOINTS } from "../request";
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  data() {
    return {
      qrValue: "",
      secret: "",
      otp: "",
      error: false,
      secretShown: true,
    }
  },
  components: {
    VueQRCodeComponent
  },
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.close();
      }
    });
    this.getSecret()
  },
  computed: {
    loading(){
      return this.$store.state.loader.loading
    } 
  },
  methods: {
    getSecret() {
      Http()
        .post(ENDPOINTS.getAuthenticatorSecretKey)
        .then((response) => {
          if (response.status) {
            this.secret = response.data.authenticatorSecret
            this.qrValue = response.data.qrData
            if (this.qrValue == null) {
              this.error = true
              this.$toast.error("Something Went Wrong Please Contact The Team");
              this.close()
            }
            if (this.secret == null || this.secret == "") {
              this.secretShown = false
            }
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    verifyAuthenticatorOtp(){
      Http()
        .post(ENDPOINTS.verifyAuthenticatorOtp,{
          userId: this.$store.state.user.userId,
          otp: this.otp
        })
        .then((response) => {
          if (response.status) {
            this.close()
            this.$store.commit("multifactor");
            this.$emit("changeShowAuthenticationBtn", false);
            if (response.dialog != null && response.dialog.title != null) {
              this.$toast.success(response.dialog.title)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    close() {
      this.$emit("close");
    },
  },
}
</script>

<style></style>