<template>
  <div class="row">
    <div class="col-4">
        <div class="mb-3">
          <label class="form-label">Image Chooser Type <span class="text-danger">*</span></label>
          <select class="form-select" aria-label="Choose Type" v-model="imageInformation.newImage">
            <option selected disabled :value="null">Choose Type</option>
            <option :value="false">Existing Images</option>
            <option :value="true">Upload New Image</option>
          </select>
        </div>
      </div>
      <div v-if="imageInformation.newImage != null && imageInformation.newImage" class="col-4">
        <div class="mb-3">
          <label class="form-label" for="newImage">Upload Image <span class="text-danger">*</span></label>
          <input class="form-control" type="file" id="newImage" v-on:change="(e) => onFileChange(e, false)">
        </div>
      </div>
      <div v-if="imageInformation.newImage != null && !imageInformation.newImage" class="col-4">
        <div class="mb-3">
          <label class="form-label">Image Url <span class="text-danger">*</span></label>
          <div class="d-inline-flex w-100">
            <v-select :options="documents" label="label" class="vselect w-100" placeholder="Choose Image" v-model="imageInformation.imageUrl" :reduce="imageUrl => imageUrl.url" >
              <template slot="option" slot-scope="option" >
                  <img :src="option.url" style="width: 50px; height: 50px" />
                  <span class="pl-4">{{ option.label }}</span>
              </template>
            </v-select>
            <button type="button" class="btn btn-secondary ms-1" @click="refreshDocuments('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
          </div>
        </div>
      </div>
      <div  v-if="imageInformation.imageUrl != null" class="col-4">
        <div class="">
            <label class="form-label mb-0" for="newImage">Image Displayer</label>
            <img  v-img:name :src="imageInformation.imageUrl" style="width: 50px; height: 50px" />
          </div>
        
      </div>
  </div>
</template>

<script>

import VueImg from "v-img";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components:{
    vSelect
  },
  data(){
    return{
      imageUrl: null,
    }
  },
    props: {
        imageInformation: Object,
        documents: Array,
        refreshDocuments: Function,
        isMenu: Boolean,
    },
    methods: {

        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createByteArray(e, files)
            this.createImage(files[0]);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                var filename = file.name
                this.imageInformation.imageName = filename.substring(0, filename.indexOf("."))
                this.imageInformation.imageUrl = e.target.result
            };
            reader.readAsDataURL(file);
        },
        async createByteArray(evt, files) {
            const selectedFile = files[0]
            const byteFile = await this.getAsByteArray(selectedFile)

            this.imageInformation.imageData = [].slice.call(byteFile)
        },
        async getAsByteArray(file) {
            return new Uint8Array(await this.readFile(file))
        },
        readFile(myFile) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                var fileByteArray = [];
                reader.readAsArrayBuffer(myFile);
                reader.onloadend = function (evt) {
                    if (evt.target.readyState == FileReader.DONE) {
                        var arrayBuffer = evt.target.result,
                            array = new Uint8Array(arrayBuffer);
                        for (var i = 0; i < array.length; i++) {
                            fileByteArray.push(array[i]);
                        }
                    }
                    this.imageInformation.imageData = fileByteArray
                }
                reader.addEventListener("loadend", e => resolve(e.target.result))
                reader.addEventListener("error", reject)

            })
        },
    }
}
</script>

<style>
.vs__dropdown-option{
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.vs__dropdown-toggle{
  padding: 5px!important;
}
</style>