<template lang="">
  <div class="mx-5 py-5">
    <div class="row mb-3">
      <div class="col-9">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Payments
        </h2>
      </div>
      <div class="col-3">
        <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-sm text-sm float-right"
        :data="data" :columns="columns" :file-name="'Payments ' + (new Date()).getTime()"
        :file-type="'xlsx'" :sheet-name="'sheetname'" >
        Export
      </vue-excel-xlsx>
      <button class="appearance-none  bg-woo shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="getPayments">Search</button>
      </div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4 d-inline-flex app">
        <label style="margin: auto 5px;">From:</label>
        <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom" ></date-time-picker>
      </div>
      <div class="col-4  d-inline-flex app">
        <label style="margin: auto 5px;">To:</label>
        <date-time-picker  v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
      </div>
    </div>
    <div class="row" v-if="data != null && data.length != 0">
      <div class="col-12">
        <GoodTable
          :name="'Payments'"
          :rows="data" :columns="columns" 
          :line-numbers="true" />
      </div>
    </div>
    <div v-else>
      <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
          <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
          <p class="text-3xl">No Data Found!</p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import "vue-toastification/dist/index.css";
import VueExcelXlsx from "vue-excel-xlsx";
import router from "../router";
import DateTimePicker from 'vue-vanilla-datetime-picker';
import  '../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css'
import { ExclamationIcon } from "@vue-hero-icons/outline";

Vue.use(VueExcelXlsx);
export default {
  components: {
    DateTimePicker,
    GoodTable,
    ExclamationIcon
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      data: null,
      applications: this.$store.state.application,
      columns: [
        {
          label: "Transaction Date",
          field: "transactionDate",
        },
        {
          label: "Transaction Id",
          field: "transactionId",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Transaction Id", 
            filterValue: "", 
          }
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Description", 
            filterValue: "", 
          }
        },
        {
          label: "Payer Account Id",
          field: "payerAccountId",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Payer Account Id", 
            filterValue: "", 
          }
        },
        {
          label: "Payer Name",
          field: "payerName",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Payer Name", 
            filterValue: "", 
          }
        },

        {
          label: "Payer Phone Number",
          field: "payerPhoneNumber",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Payer Phone Number", 
            filterValue: "", 
          }
        },

        {
          label: "Amount",
          field: "amount",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Amount", 
            filterValue: "", 
          }
        },
      ],
    };
  },
  created() {
    this.filtersHandler();
  },
  methods: {
    filtersHandler() {
      this.dateTo = new Date();
      this.dateFrom = new Date(this.dateTo - 1);
      this.dateTo.setHours(23,59,59,59);
      this.dateFrom.setHours(0,0,0,0);

      this.getPayments();
    },
    getPayments() {
      Http()
        .post(ENDPOINTS.payments, {
          dateTo: new Date(this.dateTo).getTime(),
          dateFrom: new Date(this.dateFrom).getTime(),
        })
        .then((response) => {
          if (response.status) {
            this.data = response.data;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
  },
};
</script>
<style lang=""></style>
