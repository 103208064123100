import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from './router/index'
import store from './store'
import "./assets/main.css";

Vue.use(VueRouter);
Vue.config.productionTip = false
Vue.directive("focus", {
  inserted: function(el) {
    el.focus();
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
