<template lang="">
  <div class="mx-5 py-5">
    <div class="row">
      <div class="col-9">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Active Accounts
        </h2>
      </div>
      <div class="col-3 text-end">
        <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-sm text-sm"
        :data="data" :columns="exportColumns" :file-name="'Active Accounts ' + (new Date()).getTime()"
        :file-type="'xlsx'" :sheet-name="'sheetname'" >
        Export
      </vue-excel-xlsx>
      </div>
    </div>
    <div class="row" v-if="data != null && data.length != 0">
      <div class="col-12">
        <GoodTable
          :name="'Active Accounts'"
          :rows="data" :columns="columns" :method1="deleteAccount" />
      </div>
    </div>
    <div v-else>
      <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
          <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
          <p class="text-3xl">No Data Found!</p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import router from "../router";
import VueExcelXlsx from "vue-excel-xlsx";
import { ExclamationIcon } from "@vue-hero-icons/outline";
Vue.use(VueExcelXlsx);
export default {
  components: {
    GoodTable,
    ExclamationIcon
  },
  data() {
    return {
      exportColumns: null,
      data: null,
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Phone",
          field: "phoneNumber",
        },

        {
          label: "Verification Date",
          field: "verificationDate",
        },
      ],
    };
  },
  created() {
    if(this.$store.state.privileges.deleteAccounts){
      this.columns.push({
          label: "Actions",
          field: "actions",
          actionName: "Delete",
          sortable: false,
        })
    }
    this.getAccounts();
    this.cloneArray();
  },
  methods: {
    getAccounts() {
      Http()
        .get(ENDPOINTS.accounts)
        .then((response) => {
          if (response.status) {
            this.data = response.data;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },

    deleteAccount(row) {
      Http()
        .delete(ENDPOINTS.accounts, {
          data: {
            accountIdList: [row.id],
          },
        })
        .then((response) => {
          if (response.status) {
            const indexOfObject = this.data.findIndex((object) => {
              return object.row.id === 3;
            });
            this.data.splice(indexOfObject, 1);
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },

    cloneArray() {
      this.exportColumns = JSON.parse(JSON.stringify(this.columns));
      this.exportColumns.pop();
    },
  },
};
</script>
<style lang=""></style>
