<template>
    <div>
        <button type="button" class="d-none" ref="switchServerModalBtn" data-bs-toggle="modal" data-bs-target="#switchServerModal">test</button>
        <div class="modal fade" id="switchServerModal" tabindex="-1" aria-labelledby="switchServerModal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <label class="modal-title subTitle">Switch Server</label>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label class="form-label">Choose Server <span class="text-danger">*</span></label>
                                        <select class="form-select" aria-label="Choose Server" v-model="selectedServer">
                                            <option disabled selected :value="null">Choose A Server</option>
                                            <option v-for="s in servers" :key="s.id" :value="s.id">{{s.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="closeSwitchServerModal" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="switchServer">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import {commonFunctions} from "../helpers/CommonFunctions"
import store from "../store/";
export default {
    data() {
        return {
            servers: null,
            selectedServer: null,
            checkIfEmpty: commonFunctions.checkIfEmpty,
            getPrivilges: commonFunctions.getPrivilges,
        }
    },
    created() {
        this.getServers("created");
    },
    methods: {
        getServers(source, forceGet) {
            if (source == 'created' && (forceGet || this.servers == null || this.servers.length == 0)) {
                Http()
                    .get(ENDPOINTS.getServers, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.servers = response.data.list;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getServers(source, true), 500);
                    });
            }
            return this.servers;
        },
        switchServer() {
            if (this.checkIfEmpty(this.selectedServer)) {
                this.$toast.error("Please Select A Server")
                return;
            }
            Http()
                .post(ENDPOINTS.switchServer, {
                    serverId: this.selectedServer
                })
                .then((response) => {
                    if (response.status) {
                        var tempStore = JSON.parse(JSON.stringify(store.state)) 
                        var tempStorePrivileges = JSON.parse(JSON.stringify(store.state.privileges)) 
                        store.commit("login", response.data);
                        var privileges = this.getPrivilges(response.data.privileges.map(m => m.privilege));
                        store.commit("privileges", privileges);
                        this.$refs.closeSwitchServerModal.click()
                        window.open(window.location.pathname)
                        store.commit("login", tempStore);
                        store.commit("privileges", tempStorePrivileges);
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
    }
}
</script>

<style>
.btn-primary {
    background-color: #E40046 !important;
}
</style>