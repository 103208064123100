<template>
    <div>
        <div class="row">
            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">Field Type <span class="text-danger">*</span></label>
                    <div class="d-inline-flex w-100">
                        <v-select :options="applicationFieldTypes == null ? [] : applicationFieldTypes" class="vselect w-100" placeholder="Choose Type" v-model="applicationFieldData.type"></v-select>
                        <button v-if="applicationFields != null" type="button" class="btn btn-secondary ms-1" @click="getApplicationFieldTypes('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-4" v-if="applicationFields != null">
                    <div class="mb-3">
                        <label class="form-label">Dependecies <span class="text-danger">*</span></label>
                        <div class="d-inline-flex w-100">
                            <vue-multi-select style="width: 100%; text-align: start" ref="multiSelect2" v-model="selecedDependencies" @input="onChangeInput" :options="options" :filters="filters" :btnLabel="btnLabel" :selectOptions="applicationFields">
                            </vue-multi-select>
                        <button type="button" class="btn btn-secondary ms-1" @click="getApplicationFieldsByApplicationId"><i class="bi bi-arrow-clockwise"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="applicationFieldData.type == 'spinner' || applicationFieldData.type == 'radio' || applicationFieldData.type == 'toggle' || applicationFieldData.type == 'checkbox'">
            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label" for="applicationFieldJson">Texts With Values <span
                            class="text-danger">*</span></label>
                    <input class="form-control" type="file" id="applicationFieldJson"
                        v-on:change="(e) => onExcelChange(e)">
                </div>
            </div>
            <div class="col-4" v-if="applicationFieldData.type == 'spinner'">
                <div class="mb-3">
                    <label class="form-label">Prompt <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="applicationFieldData.spinnerPrompt" />
                </div>
            </div>
            <div class="col-4" v-if="applicationFieldData.type == 'spinner'">
                <div class="mb-3">
                    <label class="form-label">Spinner File Demo</label> <br/>
                    <button type="button" class="btn btn-secondary m-auto" @click="getSpinnerFile">Downlaod</button>
                </div>
            </div>
            <div class="col-4" v-if="applicationFieldData.type == 'radio'">
                <div class="mb-3">
                    <label class="form-label">Span Count <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" v-model="applicationFieldData.radioSpanCount" />
                </div>
            </div>
            <div class="col-4" v-if="applicationFieldData.type == 'checkbox'" style="margin: auto 0">
                <div class="mb-3 form-check">
                    <input type="checkbox" class="form-check-input" id="applicationFieldStylePopup" v-model="applicationFieldData.checkboxStylePopup">
                    <label class="form-check-label" for="applicationFieldStylePopup"> Style Popup<span class="text-danger">*</span></label>
                </div>
            </div>
            <div class="col-12">
                <div class="mb-3">
                    <textarea class="form-control" rows="5" style="resize: none; width: 50%" v-model="applicationFieldData.applicationFieldTextValueString" disabled />
                </div>
            </div>
        </div>
        <FieldInputTypeData v-if="applicationFieldData.type == 'text' || applicationFieldData.type == 'phone' || applicationFieldData.type == 'numeric' || applicationFieldData.type == 'decimal'" :applicationFieldData="applicationFieldData" />
        <div class="row" v-if="applicationFieldData.type == 'date' || applicationFieldData.type == 'datetime'">
            <div class="col-4">
              <div class="mb-3">
                <label class="form-label">Format <span class="text-danger">*</span></label>
                <input type="text" class="form-control" v-model="applicationFieldData.dateFormat" />
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import FieldInputTypeData from './FieldInputTypeData.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as XLSX from "xlsx/xlsx";

export default {
    components: {
        vueMultiSelect,
        FieldInputTypeData,
        vSelect
    },
    props:{
        applicationFieldTypes: Array,
        applicationFieldData: Object,
        applicationFields: Array,
        getApplicationFieldTypes: Function,
        getApplicationFieldsByApplicationId: Function,
    },
    data() {
        return {
            btnLabel: values => `Select (${values.length})`,
            filters: [{
                nameAll: 'Select all',
                nameNotAll: 'Deselect all',
                func() {
                    return true;
                },
            }],
            options: {
                multi: true,
                groups: false,
                labelName: "title",
                labelValue: "id",
                cssSelected: option => (option.selected ? { 'color': '#E40046' } : ''),
            },
            selecedDependencies: this.applicationFieldData==null || this.applicationFields==null?[]: this.applicationFieldData.dependencies == undefined ? [] :this.applicationFields.filter(s=>this.applicationFieldData.dependencies.includes(s.id)),
            rquestData: null,
            excelColumns: ["text", "value"],
        }
    },
    watch: {
        'applicationFieldData.id'(newValue){
            this.selecedDependencies= this.applicationFieldData==null || this.applicationFields==null?[]: this.applicationFieldData.dependencies == undefined ? [] : this.applicationFields.filter(s=>this.applicationFieldData.dependencies.includes(s.id));
        },
    },
    methods: {
        getSpinnerFile(){
            let finalHeaders = ['text', 'value'];
            let data = [[{ "text": " ", "value": " "}]];

            data.forEach((array, i) => {
                let ws = XLSX.utils.json_to_sheet(array, { header: finalHeaders });
                let wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
                let exportFileName = `spinner.xlsx`;
                XLSX.writeFile(wb, exportFileName)
            });
        },
        onChangeInput(value, id){
            this.applicationFieldData.dependencies = this.selecedDependencies.map(m => m.id)
        },
        onExcelChange(e) {
            this.applicationFieldData.applicationFieldTextValueString = ""
            this.applicationFieldData.spinnerObject = [];
            this.$store.state.loader.loading = true;
            const files = e.target.files;
            if (!files.length) {
                this.$store.state.loader.loading = false;
                return;
            } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
                this.$store.state.loader.loading = false;
                this.$toast.error("File Format Is Invalid! Please Provide xlsx File");
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                    const data = ev.target.result;
                    const workbook = XLSX.read(data, {
                        type: "binary"
                    });
                    const wsname = workbook.SheetNames[0]; 
                    const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); 
                    this.excelData = ws
                    const a = workbook.Sheets[workbook.SheetNames[0]];
                    var headers = this.getHeader(a);
                    if (headers.length > 2 || headers.at(0) != "text" || headers.at(1) != "value"){
                        this.$store.state.loader.loading = false;
                        this.$toast.error("Invalid Format!")
                        return;
                    }
                    this.handleString(ws)
                    this.$store.state.loader.loading = false;
                } catch (e) {
                    this.$toast.error("Read failure!")
                    this.$store.state.loader.loading = false;
                    return;
                }
            };
            fileReader.readAsBinaryString(files[0]);
        },
        getHeader(sheet) {
            const headers = [];
            const range = XLSX.utils.decode_range(sheet["!ref"]); 
            let C;
            const R = range.s.r; 
            let i = 0;
            for (C = range.s.c; C <= range.e.c; ++C) {
                var cell =
                    sheet[
                    XLSX.utils.encode_cell({ c: C, r: R })
                    ];
                var hdr = "UNKNOWN" + C; 
                if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
                if (hdr.indexOf('UNKNOWN') > -1) {
                    if (!i) {
                        hdr = '__EMPTY';
                    } else {
                        hdr = '__EMPTY_' + i;
                    }
                    i++;
                }
                headers.push(hdr);
            }
            return headers;
        },

        handleString(data) {
            this.applicationFieldData.spinnerObject = data;
            var str = ""
            for (var i = 0; i < data.length; i++) {
                var s = data.at(i).text + ": " + data.at(i).value + "\n"
                str += s
            }
            this.applicationFieldData.applicationFieldTextValueString = str
        },
    }

}
</script>

<style></style>