<template lang="">
  <div class="">
    <div
      class="overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--header-->
          <div
            class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
          >
            <h3 v-if="this.images.length" class="text-3xl font-semibold">
              Images
            </h3>

            <h3 v-else class="text-3xl font-semibold">No Images</h3>
            <button
              v-if="this.images.length"
              type="button"
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="$emit('close')"
            >
              <span
                class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
              >
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div
            v-if="this.images.length"
            class="relative grid grid-cols-1 gap-4 sm:grid-cols-2 lg:flex lg:flex-row p-6"
          >
            <div v-for="image in this.images" v-bind:key="image.id">
              <div class="mx-4">
                <ImageComponent @close="$emit('close')" :url="image.value" :title="image.title" ></ImageComponent>

                <p class="text-xl font-bold">{{ image.title }}</p>
              </div>
            </div>
          </div>

          <!--footer-->
          <div
            class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
          >
            <button
              class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-red-500 active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="$emit('close')"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import ImageComponent from "./ImageComponent.vue";
export default {
  props: {
    images: Array,
  },
  components: {
    ImageComponent,
  },

  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.close();
      }
    });
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang=""></style>
