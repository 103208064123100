<template>
  <div class="mx-5 py-5">
    <div class="row mb-3">
      <div class="col-9">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Sales
        </h2>
      </div>
      <div class="col-3">
        <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-sm text-sm float-right"
        :data="data" :columns="columns" :file-name="'Sales ' + (new Date()).getTime()" :file-type="'xlsx'"
          :sheet-name="'Sales'" >
        Export
      </vue-excel-xlsx>
      <button class="appearance-none  bg-woo shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="getSales">Search</button>
      </div>
    </div>
    <div class="row">
      <div class="col-3 pl-0">
        <vue-multi-select style="width: 100%; text-align: start" ref="multiSelect" v-model="servicesValue" search
          :options="options" :filters="filters" :btnLabel="btnLabel" :selectOptions="services"
          @input="getDenominations">
        </vue-multi-select>
      </div>
      <div class="col-3">
        <vue-multi-select style="width: 100%; text-align: start" ref="multiSelect2" v-model="denominationsValue" search
          :options="options" :filters="filters2" :btnLabel="btnLabel2" :selectOptions="selectedDenominations">
        </vue-multi-select>
      </div>
      <div class="col-3 d-inline-flex app">
        <label style="margin: auto 5px;">From:</label>
        <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom" ></date-time-picker>
      </div>
      <div class="col-3  d-inline-flex app">
        <label style="margin: auto 5px;">To:</label>
        <date-time-picker  v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
      </div>
    </div>
    <div class="row" v-if="data != null && data.length != 0">
      <div class="col-12">
        <GoodTable
          :name="'Balance Statement'"
          :rows="data" :columns="columns" 
          :line-numbers="true" />
      </div>
    </div>
    <div v-else>
      <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
          <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
          <p class="text-3xl">No Data Found!</p>
      </div>
    </div>
  </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import DateTimePicker from "vue-vanilla-datetime-picker";
import "../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css";
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import { ExclamationIcon } from "@vue-hero-icons/outline";

export default {
  components: {
    DateTimePicker,
    GoodTable,
    vueMultiSelect,
    ExclamationIcon
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",
      data: [],
      services: [],
      denominations: [],
      selectedDenominations: [],
      columns: [
        {
          label: "Dealer Name",
          field: "dealer.name",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Dealer Name",
            filterValue: "",
          },
        },
        {
          label: "Dealer ID",
          field: "dealer.id",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Dealer ID",
            filterValue: "",
          },
        },
        {
          label: "Account Name",
          field: "account.name",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Account Name",
            filterValue: "",
          },
        },
        {
          label: "Account ID",
          field: "account.id",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Account ID",
            filterValue: "",
          },
        },
        {
          label: "Account Phone",
          field: "phone",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Account Phone",
            filterValue: "",
          },
        },
        {
          label: "Transaction ID",
          field: "transactionNumber",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Transaction ID",
            filterValue: "",
          },
        },
        {
          label: "Service",
          field: "service",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Service",
            filterValue: "",
          },
        },
        {
          label: "Denomination",
          field: "denomination.name",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Denomination",
            filterValue: "",
          },
        },
        {
          label: "Face Value",
          field: "facevalue",
          type: "number",
          formatFn: this.formatAmounts,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Face Value",
            filterValue: "",
          },
        },
        {
          label: "Dealer Amount",
          field: "dealerAmount",
          type: "number",
          formatFn: this.formatAmounts,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Dealer Amount",
            filterValue: "",
          },
        },
        {
          label: "Account Amount",
          field: "accountAmount",
          type: "number",
          formatFn: this.formatAmounts,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Account Amount",
            filterValue: "",
          },
        },
        {
          label: "Item Serial",
          field: "itemSerial",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Item Serial",
            filterValue: "",
          },
        },
        {
          label: "Transaction Date",
          field: "transactionDate",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Transaction Date",
            filterValue: "",
          },
        },
        {
          label: "Session Number",
          field: "sessionNo",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Session Number",
            filterValue: "",
          },
        },
      ],
      servicesValue: [],
      denominationsValue: [],
      servicesDisabled: false,
      denominationsDisabled: false,
      btnLabel: values => `Select Service (${values.length})`,
      btnLabel2: values => `Select Denominations (${values.length})`,
      filters: [{
        nameAll: 'Select all',
        nameNotAll: 'Deselect all',
        func() {
          return true;
        },
      }],
      filters2: [{
        nameAll: 'Select all',
        nameNotAll: 'Deselect all',
        func() {
          return true;
        },
      }],
      options: {
        multi: true,
        groups: false,
        cssSelected: option => (option.selected ? { 'color': '#E40046' } : ''),
      },
    };
  },
  created() {
    this.getServicesDenominations()
    this.filtersHandler()
  },
  computed: {

  },
  methods: {
    filtersHandler() {
      this.dateTo = new Date();
      this.dateFrom = new Date(this.dateTo - 1);
      this.dateTo.setHours(23, 59, 59, 59);
      this.dateFrom.setHours(0, 0, 0, 0);
      // this.getSales();
    },
    getSales() {
      if (this.servicesValue.length == 0 && this.denominationsValue.length == 0){
        this.$toast.error("Please Select Services/Denominations!")
        return
      }
      if (this.denominationsValue.length == 0){
        this.$toast.error("Please Select Denominations!")
        return
      }
      Http()
        .post(ENDPOINTS.sales, {
          serviceIds: this.servicesValue.map(f => f.id),
          denominationIds: this.denominationsValue.map(f => f.id),
          dateTo: new Date(this.dateTo).getTime(),
          dateFrom: new Date(this.dateFrom).getTime(),
        })
        .then((response) => {
          if (response.status) {
            this.data = response.data;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    getServicesDenominations() {
      Http()
        .post(ENDPOINTS.servicesDenominations)
        .then((response) => {
          if (response.status) {
            this.services = response.data.services;
            this.denominations = response.data.denominations;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    getDenominations() {
      this.selectedDenominations = []
      for (var i = 0; i < this.servicesValue.length; i++) {
        var serviceId = this.servicesValue[i].id;
        this.denominations[serviceId].forEach(element => {
          if (!this.selectedDenominations.includes(element)) {
            this.selectedDenominations.push(element)
          }
        });
      }
    },
    formatAmounts(value) {
      return value.toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
  }
};
</script>

<style>
.language-json {
  background-color: lightgray;
  padding: 5px;
  text-align: start;
  color: black;
  border-radius: 5px;
  margin: 5px;
}

.datetime-picker__button,
.btn-select {
  min-height: 40px !important;
  display: block !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
  background: #fff !important;
  font-size: 14px !important;
  width: 100%;
  text-align: start;
  color: #adadad;
  padding-top: 2px;
}

.datepicker {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}

.buttonLabel {
  width: 100%;
  text-align: start;
  display: flex !important;
  align-items: center !important;
}

.buttonLabel span:first-of-type {
  width: 100%;
}

.caret {
  text-align: initial;
}
</style>
