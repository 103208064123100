<template>
    <div class="mx-5 py-5">
        <div class="row mb-3">
            <div class="col-4 text-start">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate" >
                Statement
                </h2>
            </div>
            <div class="col-8 text-end d-inline-flex justify-end">
                <button class=" mx-2 bg-success shadow text-white rounded py-2 px-4  leaight" @click="getStatementsExcel">Download Excels</button>
                <button class=" mx-2  bg-woo shadow text-white rounded py-2 px-4  leading-tight" @click="getStatements">Search</button>
            </div>
        </div>
        <div class="row ">
            <div class="col-4">
                <vue-multi-select style="width: 100%; text-align: start" ref="multiSelect2" v-model="selectedServices" :options="options" :filters="filters" :btnLabel="btnLabel" :selectOptions="services">
                </vue-multi-select>
            </div>
            <div class="col-4 d-inline-flex app">
                <label style="margin: auto 5px;">From:</label>
                <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom" ></date-time-picker>
            </div>
            <div class="col-4 d-inline-flex app">
                <label style="margin: auto 5px;">To:</label>
                <date-time-picker v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
            </div>
        </div>
        <div v-if="data != null && data.length != 0">
            <div v-for="(d, index) in data" :key="index" class="row mt-3">
                <div class="col-6 text-start">
                    <label style="font-size: 20px; font-weight: 500" >{{ d.total.accountName + ' - ' + d.currency.name }}</label>
                </div>
                <div class="col-6 text-end">
                    <vue-excel-xlsx
                        class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-sm text-sm"
                        :data="d.transactions" :columns="columns" :file-name="'Statement ' + (new Date()).getTime()" :file-type="'xlsx'"
                        :sheet-name="'Collections'">
                        Export
                    </vue-excel-xlsx>
                </div>
                <div class="col-12" >
                    <GoodTable :name="d.total.accountName + ' ' + d.currency.name" :rows="d.transactions" :columns="columns" :line-numbers="true" />
                </div>
            </div>
        </div>
        <div v-else >
            <div
                class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md"
            >
                <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
                <p class="text-3xl">No Statements Found!</p>
            </div>
        </div>
    </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import DateTimePicker from "vue-vanilla-datetime-picker";
import "../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css";
import { ExclamationIcon } from "@vue-hero-icons/outline";
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import { saveAs } from 'file-saver' 
import { commonFunctions } from "../helpers/CommonFunctions";

export default {
    components: {
        DateTimePicker,
        GoodTable,
        ExclamationIcon,
        vueMultiSelect
    },
    data() {
        return {
            formatNumber: commonFunctions.formatNumber,
            dateFrom: "",
            dateTo: "",
            exportColumns: null,
            btnLabel: values => `Select (${values.length})`,
            filters: [{
                nameAll: 'Select all',
                nameNotAll: 'Deselect all',
                func() {
                    return true;
                },
            }],
            options: {
                multi: true,
                groups: false,
                labelName: "name",
                labelValue: "id",
                cssSelected: option => (option.selected ? { 'color': '#E40046' } : ''),
            },
            data: [],
            columns: [
                {
                    label: "ID",
                    field: "id",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by ID",
                        filterValue: "",
                    }
                },
                {
                    label: "Transfer ID",
                    field: "transferId",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Transfer ID",
                        filterValue: "",
                    }
                },
                {
                    label: "Description",
                    field: "description",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Description",
                        filterValue: "",
                    }
                },
                {
                    label: "Type",
                    field: "type",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Type",
                        filterValue: "",
                    }
                },
                {
                    label: "Debit",
                    field: "debit",
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Debit",
                        filterValue: "",
                    }
                },
                {
                    label: "Credit",
                    field: "credit",
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Credit",
                        filterValue: "",
                    }
                },
                {
                    label: "Balance",
                    field: "balance",
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Balance",
                        filterValue: "",
                    }
                },
                {
                    label: "Date Creation",
                    field: "dateCreation",
                },
            ],
            services: [],
            selectedServices: [],
        }
    },
    created() {
        this.filtersHandler()
        this.getApplicationsServices()
    },
    methods: {
        filtersHandler() {
            this.dateTo = new Date();
            this.dateFrom = new Date(this.dateTo - 1);
            this.dateTo.setHours(23,59,59,59);
            this.dateFrom.setHours(0,0,0,0);
            // this.getStatements();
        },
        getStatements(email) {
            if (this.selectedServices.length == 0){
                this.$toast.error("Please Select Service")
                return;
            }
            Http()
                .post(ENDPOINTS.wooStatements, {
                    fromDate: this.formatDate(this.dateFrom),
                    toDate: this.formatDate(this.dateTo),
                    serviceIds: this.selectedServices.map(m => m.id),
                    currencyId: null
                })
                .then((response) => {
                    this.data = response.data == null ? null : response.data.balances;
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        getStatementsExcel() {
            if (this.selectedServices.length == 0){
                this.$toast.error("Please Select Service")
                return;
            }
            Http()
                .post(ENDPOINTS.wooStatementsExcel, {
                    fromDate: this.formatDate(this.dateFrom),
                    toDate: this.formatDate(this.dateTo),
                    serviceIds: this.selectedServices.map(m => m.id),
                    currencyId: null
                }, { 
                    responseType: "arraybuffer", 
                })
                .then((response) => {
                    var contentType = response.headers["content-type"];
                    var b64 = Buffer.from(response.data, "binary").toString("base64");
                    var f = "data:" + contentType + ";base64," + b64;
                    saveAs(f, 'Statements' + (new Date()).getTime() + ".xlsx");
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        getApplicationsServices() {
            Http()
                .get(ENDPOINTS.applicationServices)
                .then((response) => {
                    if (response.status) {
                        this.services = response.data;
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                this.$toast.error("Internal Server Error!")
                })
        },
        formatDate(date) {
            return (
                [
                    date.getFullYear(),
                    this.padTo2Digits(date.getMonth() + 1),
                    this.padTo2Digits(date.getDate()),
                ].join('-') +
                ' ' +
                [
                    this.padTo2Digits(date.getHours()),
                    this.padTo2Digits(date.getMinutes()),
                    this.padTo2Digits(date.getSeconds()),
                ].join(':')
            );
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        }
    }
}
</script>

<style></style>