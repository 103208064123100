<template>
    <div>
        <GoodTable
        :name="'Application'"
        :rows="receivedData"
        :columns="cols"
        :line-numbers="true"
        :method1="viewImages"
        @selectedRows="getSelectedRows"
      />
      <viewImagesModal
      v-if="viewImagesModal"
      :images="images"
      @close="viewImagesModal = false"
    ></viewImagesModal>
    </div>
</template>

<script>
import viewImagesModal from "../components/ViewImagesModal.vue";
import GoodTable from "../components/GoodTable.vue";

export default {
    components: {
        viewImagesModal,
        GoodTable
    },
    props: {
        receivedData: Array,
        cols: Array,
        typeId: Number,
        imgs: Array
    },
    data() {
        return {
            viewImagesModal: false,
            images: [],
            ifBoosta: null,
            ifWedding: null,
            finalColumns: [],
        };
    },
    created() {
        this.images = [];
        this.finalColumns = this.cols 
        if (this.finalColumns.find(f => f.label == "Images") == undefined){
            this.finalColumns.push( {
                label: "Images",
                field: "actions",
                actionName: "Open",
            },)
        }
    },
    methods: {

        getSelectedRows(value){
            this.$emit("selectedRows", value)
        },
        viewImages(row) {
            this.images = this.imgs.filter(f => f.id == row.id)
            
            this.viewImagesModal = true;
        },
    },
}
</script>

<style></style>