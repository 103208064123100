<template lang="">
  <div class="mx-5 py-5">
    <div class="row mb-3">
      <div class="col-9">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Collections
        </h2>
      </div>
      <div class="col-3">
        <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-sm text-sm float-right"
          :data="data" :columns="columns"
          :file-name="'Collections ' + (new Date()).getTime()" :file-type="'xlsx'" :sheet-name="'Collections'">
        Export
      </vue-excel-xlsx>
      <button class="appearance-none  bg-woo shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="getCollections">Search</button>
      </div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4 d-inline-flex app">
        <label style="margin: auto 5px;">From:</label>
        <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom" ></date-time-picker>
      </div>
      <div class="col-4  d-inline-flex app">
        <label style="margin: auto 5px;">To:</label>
        <date-time-picker  v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
      </div>
    </div>
    <div class="row" v-if="data != null && data.length != 0">
      <div class="col-12">
        <GoodTable
          :name="'Collections'"
          :rows="data" :columns="columns" 
          :line-numbers="true" />
      </div>
    </div>
    <div v-else>
      <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
          <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
          <p class="text-3xl">No Data Found!</p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import VueExcelXlsx from "vue-excel-xlsx";
import { TDatepicker } from "vue-tailwind/dist/components";
import VueTailwind from "vue-tailwind";
import DateTimePicker from 'vue-vanilla-datetime-picker';
import  '../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css'
import { ExclamationIcon } from "@vue-hero-icons/outline";

Vue.use(VueTailwind);

Vue.use(VueExcelXlsx);
export default {
  components: {
    DateTimePicker,
    GoodTable,
    ExclamationIcon
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      data: [],
      applications: this.$store.state.application,
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by ID", 
            filterValue: "", 
          }
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Name", 
            filterValue: "", 
          }
        },
        {
          label: "Transaction ID",
          field: "transactionId",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Transaction Id", 
            filterValue: "", 
          }
        },
        {
          label: "Credit Card Number",
          field: "maskedCreditCardNumber",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Credit Card Number", 
            filterValue: "", 
          }
        },
        {
          label: "Card Holder Name",
          field: "creditCardHolderName",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Card Holder Name", 
            filterValue: "", 
          }
        },

        {
          label: "Amount",
          field: "amount",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Amount", 
            filterValue: "", 
          }
        },

        {
          label: "Amount Captured",
          field: "amountCaptured",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Amount Captured", 
            filterValue: "", 
          }
        },
        {
          label: "Status",
          field: "paymentStatus",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Status", 
            filterValue: "", 
          }
        },

        {
          label: "Note",
          field: "note",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Note", 
            filterValue: "", 
          }
        },
        {
          label: "Requestee",
          field: "requestee",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Requestee", 
            filterValue: "", 
          }
        },
        {
          label: "Phone Number",
          field: "target",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Phone Number", 
            filterValue: "", 
          }
        },
        {
          label: "Creation Date",
          field: "dateCreation",
        },
        {
          label: "Session Id",
          field: "sessionId",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Session Id", 
            filterValue: "", 
          }
        },
        {
          label: "External Id",
          field: "externalId",
          filterOptions: {
            enabled: true,
            placeholder: "Search by External Id", 
            filterValue: "", 
          }
        },
        {
          label: "UID",
          field: "uid",
          filterOptions: {
            enabled: true,
            placeholder: "Search by UID", 
            filterValue: "", 
          }
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search by Email", 
            filterValue: "", 
          }
        },
      ],
    };
  },
  created() {
    // this.getCollections(null, null);
    this.filtersHandler()
  },
  methods: {
    filtersHandler() {
      this.dateTo = new Date();
      this.dateFrom = new Date(this.dateTo - 1);
      this.dateTo.setHours(23,59,59,59);
      this.dateFrom.setHours(0,0,0,0);

      this.getCollections();
    },
    getCollections() {
      Http()
        .post(ENDPOINTS.collections, {
          dateTo: new Date(this.dateTo).getTime(),
          dateFrom: new Date(this.dateFrom).getTime(),
        })
        .then((response) => {
          if (response.status) {
            this.data = response.data;
            this.modifyData();
            if (response.dialog != null && response.dialog.message != null ) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null ) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },

    modifyData() {
      if (this.data?.length) {
        for (var i = 0; i < this.data.length; i++) {
          this.data[i].id = this.data[i].account.id;
          this.data[i].name = this.data[i].account.name;
          this.data[i].paymentStatus = this.data[i].status.name;
          this.data[i].externalId = this.data[i].externalId == null ? null : this.data[i].externalId.toString();
        }
      }
    },
  },
};
</script>
<style >
.datetime-picker-main{
  right: 0;
}

.datetime-picker__button{
  border: 1px solid black;
  padding:0.5rem 1.5rem !important;
  border-radius: 0.25rem!important;
}
</style>
