<template>
  <div id="overlay">
    <breeding-rhombus-spinner :animation-duration="500" :size="65" color="#ff1d5e" class="center" />
  </div>
</template>

<script>
import { BreedingRhombusSpinner } from "epic-spinners";
import axios from "axios";
import store from "../store";

export default {

  created() {
  },

  components: {
    BreedingRhombusSpinner,
  },
};
</script>

<style>
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  /* cursor: pointer; */
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
</style>
