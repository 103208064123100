<template>
  <div class="mx-5">
    <div>
      <div class="row my-3">
        <div class="col-9">
          <div class="text-left">
            <label class="title mt-2 font-bold">All Applications</label>
          </div>
        </div>
        <div class="col-3 m-auto">
          <t-input
          v-model="searchKey"
          @change="searchHandler"
          @keydown="searchHandler"
          placeholder="Search"
        />
        </div>
      </div>
      <div class="row">
        <div class="col-2 mb-3"  v-for="app in filteredApplications" :key="app.id" @click="getApplicationSubmission(app, app.label)">
          <div
            class="box bg-woo"
            style="width: 200px; height: 90px; padding-top: 5px;"
          
            
          >
            <label class="font-medium">{{ app.label.replaceAll(".", " ").replaceAll("title", "").toUpperCase() }}</label>
            <br />
            <div
              class="text-center me-3"
              style="margin-top: 5px;"
              v-if="app.icon.startsWith('https')" 
            >
              <img :src="app.icon" style="width: 70px; height:40px; margin:auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TInput } from "vue-tailwind/dist/components";
import Http, { ENDPOINTS } from "../request";
import { commonFunctions } from "../helpers/CommonFunctions";
export default {
  components: {
      TInput
  },
  data() {
    return {
      formatNumber: commonFunctions.formatNumber,
      applications: [],
      filteredApplications:[],
      columnsExport: null,
      searchKey:"",
      showStatus: false,
    };
  },
  created() {
    this.setColumnsExport();
    Http()
      .get(ENDPOINTS.apps)
      .then((response) => {
        if(response.status){
          this.applications = response.data;
          this.filteredApplications = response.data;
          if(response.dialog != null && response.dialog.message != null ){
            this.$toast.success(response.dialog.message)
          }
        } else {
          if(response.dialog != null && response.dialog.message != null ){
            this.$toast.error(response.dialog.message)
          }
        }
      })
      .catch((error) => {
        this.$toast.error("Internal Server Error!")
      })
  },
  watch: {
    searchKey(){
      this.searchHandler();
    }
  },
  methods: {
    setColumnsExport(){
      this.columnsExport = [
        {
          label: "Application ID",
          field: "id",
          index: 0,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Application ID", 
            filterValue: "", 
          }
        },
        {
          label: "Account ID",
          field: "accountId",
          index: 100,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Account ID", 
            filterValue: "", 
          }
        },
        {
          label: "Account Name",
          field: "accountName",
          index: 200,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Account Name", 
            filterValue: "", 
          }
        },
        {
          label: "Phone",
          field: "accountPhone",
          index: 300,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Phone", 
            filterValue: "", 
          }
        },
        {
          label: "Transaction ID",
          field: "transactionId",
          index: 400,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Transaction ID", 
            filterValue: "", 
          }
        },
        {
          label: "Operation ID",
          field: "operationId",
          index: 405,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Operation ID", 
            filterValue: "", 
          }
        },
        {
          label: "Reversed",
          field: "reversed",
          index: 410,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Reverse", 
            filterValue: "", 
          }
        },
        {
          label: "Balance",
          field: "balance",
          index: 415,
          formatFn: f => (this.formatNumber(f)),
          filterOptions: {
            enabled: true,
            placeholder: "Search by Balance", 
            filterValue: "", 
          }
        },
        {
          label: "Creation Date",
          field: "dateCreation",
          index: 500,
        },
      ]
    },
    getApplicationSubmission(application, label) {
      const data = {
        typeId: application.id,
        label: label.split(" ")[0],
        allowAcceptReject: application.allowAcceptOrReject,
        allowEditDepositor: application.allowEditDepositor,
        showStatus: application.showStatus,
        columnsExport: this.columnsExport
      };
      this.$router.push({ name: 'ApplicationsSubmission', params: data });
    },
    searchHandler(){
      const result = this.applications.filter((obj) =>
      obj.label.toLowerCase().includes(this.searchKey.toLowerCase())
      );
      this.filteredApplications = result;
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 1.5em;
}

.active {
  color: white !important;
}

a {
  color: white !important;
}

a:hover {
  color: black !important;
  text-decoration: none !important;
}

button:hover {
  color: white;
  background-color: transparent !important;
}

.box {
  color: white;
  cursor: pointer;
  transition: 0.4s;
  margin-right: 15px;
  text-align: center;
}

.box:hover {
  box-shadow: 0px 0px 10px 1px rgba(125, 125, 125, 0.75);
  transform: scale(1.1);
  transition: 0.4s;
}
</style>
