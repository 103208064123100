<template>
    <div class="mx-5 py-5">
        <div class="row mb-3">
          <div class="col-9">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Online Payments
            </h2>
          </div>
          <div class="col-3">
            <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-sm text-sm float-right"
              :data="data" :columns="columns"
              :file-name="'OnlinePayments ' + (new Date()).getTime()" :file-type="'xlsx'" :sheet-name="'OnlinePayments'">
            Export
          </vue-excel-xlsx>
          <button class="appearance-none  bg-woo shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="getOnlinePayments">Search</button>
          </div>
        </div>
        <div class="row">
            <div class="col-4 d-inline-flex app" v-if="this.$store.state.privileges.OnlinePaymentsSearchByAccountId">
                <label style="margin: auto 5px;">ID:</label>
                <input class="form-control" v-model="accountId" placeholder="Account ID" />
            </div>
          <div class="col-4" v-else></div>
          <div class="col-4 d-inline-flex app">
            <label style="margin: auto 5px;">From:</label>
            <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom" ></date-time-picker>
          </div>
          <div class="col-4  d-inline-flex app">
            <label style="margin: auto 5px;">To:</label>
            <date-time-picker  v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
          </div>
        </div>
        <div class="row" v-if="data != null && data.length != 0">
          <div class="col-12">
            <GoodTable
              :name="'Online Payments'"
              :rows="data" :columns="columns" 
              :line-numbers="true" />
          </div>
        </div>
        <div v-else>
          <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
              <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
              <p class="text-3xl">No Data Found!</p>
          </div>
        </div>
      </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import DateTimePicker from 'vue-vanilla-datetime-picker';
import '../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css'
import { ExclamationIcon } from "@vue-hero-icons/outline";
import { commonFunctions } from "../helpers/CommonFunctions";
export default {
    components: {
        DateTimePicker,
        GoodTable,
        ExclamationIcon,
    },
    data() {
        return {
            formatNumber: commonFunctions.formatNumber,
            dateFrom: '',
            dateTo: '',
            data: null,
            accountId: null,
            columns: [
                {
                    label: "Transaction Id",
                    field: "transactionId",
                    type: "number",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Transaction Id",
                        filterValue: "",
                    }
                },
                {
                    label: "Topup Transaction Id",
                    field: "topupTransactionId",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Topup Transaction Id",
                        filterValue: "",
                    }
                },
                {
                    label: "External Id",
                    field: "externalId",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by External Id",
                        filterValue: "",
                    }
                },
                {
                    label: "Target",
                    field: "target",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Target",
                        filterValue: "",
                    }
                },
                {
                    label: "Invoice",
                    field: "invoice",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Invoice",
                        filterValue: "",
                    }
                },
                {
                    label: "Amount",
                    field: "amount",
                    type: "number",
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Amount",
                        filterValue: "",
                    },
                },
                {
                    label: "Status",
                    field: "status",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Status",
                        filterValue: "",
                    }
                },
                {
                    label: "Creation Date",
                    field: "dateCreation",
                },
                {
                    label: "Payment Date",
                    field: "datePayment",
                },
            ]
        }
    },
    created() {
        this.filtersHandler();
    },
    methods: {
        filtersHandler() {
            this.dateTo = new Date();
            this.dateFrom = new Date(this.dateTo - 1);
            this.dateTo.setHours(23, 59, 59, 59);
            this.dateFrom.setHours(0, 0, 0, 0);

            this.getOnlinePayments();
        },
        getOnlinePayments() {
            Http()
                .post(ENDPOINTS.onlinePayments, {
                    dateTo: new Date(this.dateTo).getTime(),
                    dateFrom: new Date(this.dateFrom).getTime(),
                    accountId: this.accountId,
                })
                .then((response) => {
                    if (response.status) {
                        this.data = response.data;
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Something Went Wrong");
                });
        },
    },
}
</script>

<style></style>