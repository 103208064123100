<template>
  <div class="w-48 h-52">
    <img
      v-if="loading"
      slot="preloader"
      src="../assets/redLoader.gif"
      class="w-48 h-52"
    />
    <p v-if="error" class="my-12 italic text-md">Error loading media</p>
    <img v-img:name :src="image" class="w-48 h-52" />
  </div>
</template>

<script>
import Vue from "vue";
import VueImg from "v-img";

Vue.use(VueImg);
import Http, { ENDPOINTS } from "../request";

export default {
  components() {},

  data() {
    return {
      loading: true,
      image: null,
      error: false,
    };
  },
  created() {
    if(this.title == "Iskan Receipt"){
      this.iskanReceipt()
    } else {
      this.fetchImage();
    }
  },
  methods: {
    fetchImage() {
      Http()
        .get(ENDPOINTS.image + this.url, { responseType: "arraybuffer" })
        .then((response) => {
          if (response.status) {
            var contentType = response.headers["content-type"];
            var b64 = Buffer.from(response.data, "binary").toString("base64");
            this.image = "data:" + contentType + ";base64," + b64;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    iskanReceipt() {
      this.$emit("close");
      window.open(this.url, '_blank');

    },
  },
  props: {
    url: String,
    title: String,
  },
};
</script>

<style></style>
