<template lang="">
  <div v-if="showCollect" class="">
    <div class="lg:flex lg:items-center lg:justify-between lg:mx-12 mx-12">
      <div class="flex justify-center lg:justify-start mt-4 min-w-0">
        <div>
          <h2
            class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Collect
          </h2>
        </div>
      </div>
      <div
        class="mt-4 gap-3 w-min flex flex-col justify-center md:flex-row lg:mt-0 lg:ml-4"
      >
        <!-- Search input -->
      </div>
    </div>

    <div class="mx-4 my-10 flex justify-center">
      <div>
        <form class="w-full">
          <div class="flex flex-wrap -mx-3 mb-6">
            <!-- <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
                for="grid-first-name"
              >
                Requester
              </label>
              <input
                v-model="requester"
                class="appearance-none block w-full text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="text"
              />
            </div> -->
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              >
                Requestee
              </label>
              <input
                v-model="requestee"
                class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              >
                Phone Number
              </label>
              <!-- <input
                v-model="target"
                class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                required
              /> -->

              <VuePhoneNumberInput
                no-example
                :preferred-countries="['LB', 'AE']"
                default-country-code="LB"
                v-model="target"
                @update="phoneNumber = $event.formattedNumber"
                color="#000000"
                valid-color="#000000"
                error-color="#000000"
                size="lg"
                class="appearance-none block w-full text-gray-700 border border-gray-200 rounded pb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              >
                Note
              </label>
              <input
                v-model="invoice"
                class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>
          </div>
          <div class="flex flex-row -mx-3 mb-2">
            <div
              class="flex justify-center pt-4 items-center w-max md:w-1/2 px-3 mb-6 md:mb-0 flex-row space-x-3 content-center"
            >
              <p
                :class="
                  fixedAmount ? 'text-gray-400 ' : 'text-gray-800 font-semibold'
                "
              >
                Variable
              </p>
              <switches v-model="fixedAmount" color="default"></switches>
              <p
                :class="
                  fixedAmount ? 'text-gray-800 font-semibold' : 'text-gray-400'
                "
              >
                Fixed
              </p>
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              >
                Amount
              </label>

              <input
                :disabled="!fixedAmount"
                v-model="amount"
                class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)))"
              />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
              >
                Type
              </label>

              <div class="relative">
                <select
                  v-model="type"
                  class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="1">One Time Success</option>
                  <option value="2">One Time Attempt</option>
                  <option value="3">Multiple Times Success</option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="flex mt-8 space-x-2 justify-center" v-if="this.$store.state.privileges.collectAction">
            <button
              :disabled="
                target == '' ||
                requestee == '' ||
                invoice == '' ||
                type == '' ||
                (amount == '' && fixedAmount == true)
              "
              @click="submitForm"
              type="button"
              class="appearance-none block w-full text-lg bg-woo shadow text-white rounded py-3 px-4 mb-3 leading-tight"
            >
              Generate
            </button>
          </div>
        </form>

        <div
          v-if="generatedUrl"
          class="bg-white mt-12 p-3 w-full rounded shadow-md mb-3"
        >
          <label
            class="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
          >
            Generated URL
          </label>
          <input
            disabled
            type="text"
            class="border-solid border rounded py-2 px-4"
            :value="generatedUrl"
          />
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
            @click.stop.prevent="copyText"
          >
            Copy
          </button>
          <input type="hidden" id="input-copy" :value="generatedUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Switches from "vue-switches";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
Vue.use(VuePhoneNumberInput);
import Http, { ENDPOINTS } from "../request";
import "vue-toastification/dist/index.css";
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);
export default {
  data() {
    return {
      fixedAmount: true,
      showCollect: false,
      generatedUrl: "",
      target: "",
      requestee: "",
      invoice: "",
      amount: "",
      type: "",
      tempAmount: null,
      phoneNumber: null,
    };
  },
  components: {
    Switches,
    VuePhoneNumberInput,
  },
  created() {
    this.showCollect = this.$store.state.privileges.Collect;
  },
  watch: {
    fixedAmount(newVal, oldVal) {
      if (newVal == false) {
        this.tempAmount = this.amount;
        this.amount = "";
      } else if (newVal == true && this.tempAmount) {
        this.amount = this.tempAmount;
      }
    },
  },
  methods: {
    copyText() {
      let codeToCopy = document.querySelector("#input-copy");
      codeToCopy.setAttribute("type", "text");
      codeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$toast.success("Copied to clipboard");
        } else {
          this.$toast.error("Oops, unable to copy");
        }
      } catch (err) {
        this.$toast.error("Oops, unable to copy");
      }

      /* unselect the range */
      codeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    submitForm() {
      this.postCollect();
    },

    postCollect() {
      Http()
        .post(ENDPOINTS.collect, {
          amount: parseFloat(this.amount),
          type: parseInt(this.type),
          requestee: this.requestee,
          invoice: this.invoice,
          target: this.phoneNumber,
        })
        .then((response) => {
          if (response.status) {
            this.generatedUrl = response.data.collectUrl;
            if (response.dialog != null && response.dialog.message != null ) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null ) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
        .finally((response) => {
          (this.target = ""),
            (this.requestee = ""),
            (this.invoice = ""),
            (this.amount = ""),
            (this.type = "");
        });
    },
  },
};
</script>
<style>
.country-selector__input[data-v-46e105de] {
  border: none !important;
}
.input-tel__input[data-v-e59be3b4] {
  border: none !important;
}
</style>
