import axios from 'axios'
import store from './store/'
import Vue from "vue";
import Toast from "vue-toastification";
Vue.use(Toast);

axios.defaults.showLoader = true

// const ADMIN_BASE_LIVE = 'https://lb.admin.whish.money/admin'
// const ADMIN_BASE_TESTING = 'https://lb.sandbox.whish.money/admin'

const ADMINBASE = process.env.SERVER_BASE_URL
// process.env.NODE_ENV == 'production-lb' ? ADMIN_BASE_LIVE : ADMIN_BASE_TESTING

export const ENDPOINTS = {
  login: `auth/login/`,
  apps: `third-party/applications/`,
  applications: `third-party/application-submission`,
  applicationsPatch: `third-party/application-submission/status`,
  collect: `third-party/collect`,
  collections: `third-party/collections`,
  accounts: `third-party/tags/accounts`,
  payments: `third-party/payments`,
  image: `third-party/s3/`,
  balanceStatement: `third-party/balance-statement`,
  sales: `third-party/sales`,
  servicesDenominations: `third-party/get/services/denominations/beans`,
  getAuthenticatorSecretKey: `get/authenticator/secret`,
  verifyAuthenticatorOtp: `verify/authenticator/otp`,
  getJsonReceipts: `third-party/get/json/receipt`,
  wooStatements: `third-party/statements`,
  wooStatementsExcel: `third-party/statements/excel`,
  applicationServices: `third-party/get/services`,
  collectionApiReport: `third-party/get/collection/api`,
  servicesBeans: `third-party/get/servcies/beans`,
  serviceTypesBeans: `third-party/get/service/types/beans`,
  denominationBeans: `third-party/get/denominations/beans`,
  adminUserList: `third-party/get/admin/users`,
  globalOperatorsBeans: `third-party/get/global/operators/beans`,
  documents: `third-party/get/documents`,
  webServers: `third-party/get/webserver/beans`,
  stringFields: `third-party/get/string/fields`,
  tags: `third-party/get/tags`,
  applicationFieldTypes: `third-party/get/application/field/types`,
  applicationFieldIdentifier: `third-party/get/application/field/identifiers`,
  externalProvidersBeans: `third-party/get/external/providers/beans`,
  priceTypes: `third-party/get/price/types`,
  currencies: `third-party/get/currencies`,
  applicationFieldsById: `third-party/get/fields/by/application`,
  collectionApplications: `third-party/get/collection/applications`,
  billMenuItems: `third-party/get/bill/menu-items`,
  comments: `third-party/get/comments`,
  accountings: `third-party/get/accountings`,
  schemaPrices: `third-party/get/schema/prices`,
  checkFakeItem: `third-party/check/fake/item`,
  checkDenomination: `third-party/check/denomination`,
  checkAccountingAndComment: `third-party/check/accounting/comment`,
  changeCommentAndAccounting: `third-party/change/accounting/comment`,
  getAccount: `third-party/get/account`,
  createService: `third-party/create/service`,
  createDenomination: `third-party/create/denomination`,
  createAccounting: `third-party/create/accounting`,
  createComment: `third-party/create/comment`,
  createSchemaPrice: `third-party/create/schema/price`,
  createFakeItem: `third-party/create/fake/item`,
  createProviderDenominaitonMapping: `third-party/create/provider/denomination/mapping`,
  createApplication: `third-party/create/application`,
  createApplicationField: `third-party/create/application/field`,
  createMenuItem: `third-party/create/menu-item`,
  deleteSchemaPrice: `third-party/delete/schema/price`,
  setApplicationReceiptData: `third-party/set/application/receipt/data`,
  onlinePayments: `third-party/get/online/payments`,
  createAdminUser: `third-party/create/admin/user`,
  editDepositor: `third-party/update/wedding/depositor`,
  userDenominations: `third-party/get/user/denominations`,
  uploadPayments: `third-party/upload/payments`,
  uploadPaymentsConfigurations: `third-party/upload/payments/configurations`,
  uploadedPayments: `third-party/get/uploaded/payments`,
  editPaymentStatus: `third-party/edit/payment/status`,
  uploadedPaymentConfiguration: `third-party/get/uploaded/payment/configuration`,
  uploadOnePayment: `third-party/upload/one/payment`,
  bulkDeletePayments: `third-party/bulk/delete/payment`,
  getServers: `get/servers`,
  switchServer: `switch/server`,
  refreshApplications: `third-party/refresh/applications`,
  refreshDenominations: `third-party/refresh/denominations`,
  refreshStrings: `third-party/refresh/strings`,
  updateAccountVersion: `third-party/update/account/version`,
  checkReceiverAccountId: `third-party/check/receiver/account/id`,
  schoolBeans: `get/global/schools/beans`,
  createSchool: `schools`,
  confirmSchool: `third-party/confirm/school`,
}

export default () => {
  let headers = {
    sessionid: store.state?.sessionId || null,
    token: store.state?.token || null,
    uid: 'mac-FVFFF04EQ05P',
    operatingsystem: 4,
    loggedinuser: 'webuser',
    language: 'en',
    webadminversion: 1004,
    ...(store.state && store.state?.server && store.state?.server?.id
    ? {serverid: store.state?.server?.id || null,}
    : {}),
  }

  const instance = axios.create({
    withCredentials: false,
    // timeout: 100 * 60 * 1,
    timeout: 1000 * 60 * 3,
    baseURL: ADMINBASE,
    headers,
  })

  instance.interceptors.request.use(
    (config) => {
      var lastRequest = store.state.lastRequest
      if (lastRequest != null) {
        var now = new Date()
        var timeDifference = Math.abs(now - lastRequest) / 36e5;
        if (timeDifference > 24) {
          Vue.$toast.error("Please Login Again")
          store.commit("logout")
          return;
        }
      }
      if (config.showLoader) {
        store.dispatch('loader/pending')
      }
      store.commit("lastRequest")
      return config
    },
    (error) => {
      if (error.config.showLoader) {
        store.dispatch('loader/done')
      }
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    (response) => {
      if (response.config.showLoader) {
        store.dispatch('loader/done')
      }
      if(response.request.responseType=='arraybuffer'){
        return response;
      }
      return response.data;
    },
    (error) => {
      store.dispatch('loader/done')
      return Promise.reject(error)
    },
  )

  // instance.interceptors.response
  return instance
}
