<template>
  <div class="mx-5 py-5">
    <div class="row mb-3">
      <div class="col-9">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {{title }}
        </h2>
      </div>
      <div class="col-3">
        <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-sm text-sm float-right"
          :data="data" :columns="columns"
          :file-name="title + new Date().getTime().toString()"
          :file-type="'xlsx'" :sheet-name="title" >
        Export
      </vue-excel-xlsx>
      <button class="appearance-none  bg-woo shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="getReport">Search</button>
      </div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-4 d-inline-flex app">
        <label style="margin: auto 5px;">From:</label>
        <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom" ></date-time-picker>
      </div>
      <div class="col-4  d-inline-flex app">
        <label style="margin: auto 5px;">To:</label>
        <date-time-picker  v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
      </div>
      <div class="col-12 mt-3 m-auto">
        <div class="form-check form-switch text-right float-right">
          <input class="form-check-input" type="checkbox" role="switch" id="reverse" v-model="includeReversed">
          <label class="form-check-label" for="reverse">Include Reversed</label>
        </div>
      </div>
    </div>
    <div class="row" v-if="data != null && data.length != 0">
      <div class="col-12">
        <GoodTable 
        :name="title" :rows="data" :columns="columns"
        :line-numbers="true"
      />
      </div>
    </div>
    <div v-else>
      <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
          <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
          <p class="text-3xl">No Data Found!</p>
      </div>
    </div>
  </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import GoodTable from "../components/GoodTable.vue";
import DateTimePicker from "vue-vanilla-datetime-picker";
import "../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css";
import { TRichSelect } from "vue-tailwind/dist/components";
import { ExclamationIcon } from "@vue-hero-icons/outline";
import { commonFunctions } from "../helpers/CommonFunctions";

export default {
  components: {
    DateTimePicker,
    GoodTable,
    ExclamationIcon,
  },
  data() {
    return {
      formatNumber: commonFunctions.formatNumber,
      dateFrom: "",
      dateTo: "",
      data: [],
      title: "Collection Report",
      includeReversed: false,
      columns: [
        {
          label: "Transaction ID",
          field: "transactionId",
          index: 1,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Transaction ID",
            filterValue: "",
          },
        },
        {
          label: "Web Transaction ID",
          field: "webTransactionId",
          index: 1,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Web Transaction ID",
            filterValue: "",
          },
        },
        {
          label: "External ID",
          field: "externalId",
          index: 1,
          filterOptions: {
            enabled: true,
            placeholder: "Search by External ID",
            filterValue: "",
          },
        },
        {
          label: "Transaction Type",
          field: "transactionType",
          index: 2,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Transaction Type",
            filterValue: "",
          },
        },
        {
          label: "Amount",
          field: "amount",
          index: 8,
          formatFn: f => (this.formatNumber(f)),
          filterOptions: {
            enabled: true,
            placeholder: "Search by Amount",
            filterValue: "",
          },
        },
        {
          label: "Currency",
          field: "currency.name",
          index: 9,
          filterOptions: {
            enabled: true,
            placeholder: "Search by Currency",
            filterValue: "",
          },
        },
        {
          label: "Date Creation",
          field: "dateCreation",
          index: 10,
        },
      ]
    };
  },
  created() {
    this.filtersHandler();
  },
  methods: {
    filtersHandler() {
      this.dateTo = new Date();
      this.dateFrom = new Date(this.dateTo - 1);
      this.dateTo.setHours(23, 59, 59, 59);
      this.dateFrom.setHours(0, 0, 0, 0);
      this.getReport();
    },
    getReport() {
      Http()
        .post(ENDPOINTS.collectionApiReport, {
          includeReversed: this.includeReversed,
          dateTo: new Date(this.dateTo).getTime(),
          dateFrom: new Date(this.dateFrom).getTime(),
        })
        .then((response) => {
          if (response.status) {
            this.data = response.data;
            var index = 4
            this.data.forEach((element) => {
              if(element.columns != null){
                element.columns.forEach((el) => {
                  var name = el.name
                  var val = el.value
                  element[name] = val
                  if (!this.findObjetInArray(name)) {
                    var obj = {
                      "label": name,
                      "field": name,
                      "index": index++,
                      filterOptions: {
                        enabled: true,
                        placeholder: "Search by " + name,
                        filterValue: "",
                      }
                    }
                    this.columns.push(obj)
                  }
                })
              }
            })
            this.columns.sort((a, b) => a.index - b.index)
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    findObjetInArray(title){
      return this.columns.find(f => f.field == title)!=undefined
    },
  },
};
</script>

<style></style>
