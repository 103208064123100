<template>
  <div>
    <div class="row mt-3">
        <div class="col-12">
          <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Provider</label>
        </div>
        <div class="col-6">
          <div class="mb-3 form-check">
            <input type="radio" name="setProviderMapping" class="form-check-input" id="whishMoneyProvider" :value="true" data-bs-toggle="modal" data-bs-target="#newProviderMappingModal" />
            <label class="form-check-label" for="whishMoneyProvider">Whish Money</label>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3 form-check">
            <input type="radio" name="setProviderMapping" class="form-check-input" id="newProviderMapping" v-model="newProviderMapping" :value="false" />
            <label class="form-check-label" for="newProviderMapping">No Provider</label>
          </div>
        </div>
      </div>
      <div class="modal fade" id="newProviderMappingModal" tabindex="-1" aria-labelledby="newProviderMappingModal" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <label class="modal-title subTitle">New Provider Mapping</label>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="form-label">Whish Money Service ID <span class="text-danger">*</span></label>
                      <input type="number" class="form-control" v-model="denomination.externalId" />
                    </div>
                  </div>
                  <div class="col-4" v-if="applicationType == 'online'">
                    <div class="mb-3">
                      <label class="form-label">Bill Type <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="providerBillType" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" ref="closeMappingModal" data-bs-dismiss="modal" @click="newProviderMapping = true">Close</button>
              <button type="button" class="btn btn-primary"  @click="createNewProviderMapping">Create Provider Mapping</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"

export default {
  props:{
      denomination: Object,
      applicationType: String
  },
  data(){
      return{
        checkIfEmpty: commonFunctions.checkIfEmpty,
        newProviderMapping: null,
        providerBillType: null,
        showCreateSchemaPrice: false,
      }
  },
  watch: {
    newProviderMapping() {
      if (!this.newProviderMapping) {
        this.showCreateSchemaPrice = true
      }
    },
    showCreateSchemaPrice() {
      this.$emit('changeShowCreateSchemaPrice', this.showCreateSchemaPrice)
    },
  },
  methods: {
    createNewProviderMapping() {
      if (this.checkIfEmpty(this.denomination.externalId) || this.checkIfEmpty(this.providerBillType) && this.applicationType == 'online') {
        this.$toast.error("Please Fill All Required Fields")
      } else {
        Http()
          .post(ENDPOINTS.createProviderDenominaitonMapping, {
            denominationId: this.denomination.id,
            externalDenomination: this.denomination.externalId,
            billType: this.providerBillType,
          })
          .then((response) => {
            if (response.status) {
              this.newProviderMapping = false
              this.showCreateSchemaPrice = true
              this.$refs.closeMappingModal.click()
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          })
          .catch((error) => {
            this.$toast.error("Internal Server Error!")
          })
      }
    },
  }
}
</script>


<style scoped>
.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>