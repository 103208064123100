<template lang="">
  <div class="w-full mt-4">
    <div
      class="relative overflow-x-auto shadow-md sm:rounded-lg content-center"
    >
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          externalQuery: searchQuery,
          //    externalQuery: searchTerm
        }"
        
        :select-options="{ enabled: true }"
        @on-selected-rows-change="selectionChanged"
        compactMode
      >
        <template slot="table-row" slot-scope="props"> 
          <!-- Actions for active accounts page -->
          <span v-if="props.column.field == 'actions'">
            <button
              class="bg-woo hover:bg-gray-400 text-white font-bold py-1 px-4 rounded inline-flex items-center"
              @click="method1(props.row)"
            >
              {{ props.column.actionName }}
            </button>
          </span>

          <span v-if="props.column.field == 'reversed'">
            <span v-if="props.row.reversed"
              ><label class="text-success m-auto">True</label></span>
            <span v-else><label class="text-danger m-auto">False</label></span>
          </span>

          <!--  Actions for Others -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import Http, { ENDPOINTS } from "../request";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { CheckIcon, XIcon } from "@vue-hero-icons/outline";

export default {
  created() {},

  components: {
    VueGoodTable,
    // CheckIcon,
    // XIcon,
  },

  data() {
    return {
      searchQuery: null,
    };
  },
  props: {
    name: String,
    columns: Array,
    rows: Array,
    method1: { type: Function },
  },

  methods: {
    closeModal() {
      // this.deleteProject = false;
      this.selectedRowId = null;
    },
    selectionChanged(params) {
      this.$emit("selectedRows", params.selectedRows);
    },
  },
};
</script>
<style>
.sortable:hover {
  color: black !important;
}

button:hover {
  background-color: #f3f4f7 !important;
  color: black !important;
}

.vgt-table thead th :hover {
  color: red !important;
}

tr:hover {
  background-color: transparent !important;
}

.vgt-table td {
  text-align: center !important;
}
.vgt-table th {
  text-align: center !important;
}
</style>
