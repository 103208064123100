<template>
    <div>
        <div class="row" v-if="applicationFieldData.type == 'numeric' || applicationFieldData.type == 'decimal'">
            <div class="col-4">
              <div class="mb-3">
                <label class="form-label">Hint </label>
                <input type="text" class="form-control" v-model="applicationFieldData.hint" />
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label class="form-label">Minimum Number</label>
                <input type="number" class="form-control" v-model.number="applicationFieldData.minNumber" />
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label class="form-label">Maximum Number</label>
                <input type="number" class="form-control" v-model.number="applicationFieldData.maxNumber" />
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label class="form-label">Minimum Character</label>
                <input type="text" class="form-control" v-model="applicationFieldData.minChar" />
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label class="form-label">Maximum Character</label>
                <input type="text" class="form-control" v-model="applicationFieldData.maxChar" />
              </div>
            </div>
            <div class="col-4">
              <div class="mb-3">
                <label class="form-label">Wrong Input Hint</label>
                <input type="text" class="form-control" v-model="applicationFieldData.wrongInputHint" />
              </div>
            </div>
        </div>
        <div class="row" v-if="applicationFieldData.type == 'text'">
            <div class="col-4" >
              <div class="mb-3">
                <label class="form-label">Hint</label>
                <input type="text" class="form-control" v-model="applicationFieldData.hint" />
              </div>
            </div>
        </div>
        <div class="row" v-if="applicationFieldData.type == 'phone'">
            <div class="col-4" style="margin: auto 0">
              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="applicationFieldRetrieveSavedContact" v-model="applicationFieldData.retrieveSavedContact">
                <label class="form-check-label" for="applicationFieldRetrieveSavedContact"> Retrieve Saved Contact<span class="text-danger"> *</span></label>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        applicationFieldData: Object,
    },
}
</script>

<style></style>