<template>
  <nav class="bg-woo shadow" id="app">
    <div class="mx-5 py-3">
      <div class="md:flex justify-between items-center">
        <!-- left section -->
        <div class="flex justify-between items-center">
          <button
            @click="goToMainPage"
            class="text-gray-800 text-xl font-bold hover:text-gray-700 md:text-2xl"
          >
            <img
              width="200px"
              height="200px"
              src="../assets/images/whish-cropped.png"
            />
          </button>
          <div class="md:hidden">
            <button type="button" class="text-white" @click="isOpen = !isOpen">
              <MenuIcon v-if="!isOpen" />
              <XIcon v-else />
            </button>
          </div>

          <small class="pt-5 text-white">{{serverName}}</small>
        </div>
        <!-- right section -->
        <div
          class="flex-col mt-3 md:flex-row md:mt-0 md:flex"
          :class="isOpen ? 'flex' : 'hidden'"
          @click="isOpen = !isOpen"
        >
          <router-link
            v-if="this.$store.state.privileges.Applications"
            active-class="active"
            to="/applications"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <DocumentDuplicateIcon />
            <span class="ml-3">Applications</span>
          </router-link>

          <router-link
            v-if="this.$store.state.privileges.Collect"
            active-class="active"
            to="/collect"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <HandIcon />
            <span class="ml-3">Collect</span>
          </router-link>

          <router-link
            v-if="this.$store.state.privileges.Collections"
            active-class="active"
            to="/collections"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <CollectionIcon />
            <span class="ml-3">Collections</span>
          </router-link>

          <router-link
            v-if="this.$store.state.privileges.Payments"
            active-class="active"
            to="/payments"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <CurrencyDollarIcon />
            <span class="ml-3">Payments</span>
          </router-link>

          <router-link
            v-if="this.$store.state.privileges.ActiveAccounts"
            active-class="active"
            to="/active-accounts"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <IdentificationIcon />
            <span class="ml-3">Active Accounts</span>
          </router-link>

          <router-link
            v-if="this.$store.state.privileges.BalanceStatement"
            active-class="active"
            to="/balance-statement"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <ScaleIcon />
            <span class="ml-3">Balance Statement</span>
          </router-link>

          <router-link
            v-if="this.$store.state.privileges.Sales"
            active-class="active"
            to="/sales"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <ChartBarIcon />
            <span class="ml-3">Sales</span>
          </router-link>

          <router-link
            v-if="this.$store.state.privileges.Statement"
            active-class="active"
            to="/statement"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <ChartSquareBarIcon />
            <span class="ml-3">Statement</span>
          </router-link>

          <router-link
          v-if="this.$store.state.privileges.CollectionApi"
            active-class="active"
            to="/collection-report"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <CollectionIcon />
            <span class="ml-3">Collection Report</span>
          </router-link>
          <router-link v-if="this.$store.state.privileges.OnlinePayments"
            active-class="active"
            to="/online-payments"
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:text-white"
          >
            <CashIcon />
            <span class="ml-3">Online Payments</span>
          </router-link>
          <a
            @click="logout()"
            class="flex items-center p-2 text-base font-normal text-black rounded-lg cursor-pointer hover:text-white"
          >
            <LogoutIcon />
            <span class="ml-3">Logout</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import router from "../router/index";
import {
  LogoutIcon,
  CollectionIcon,
  IdentificationIcon,
  DocumentDuplicateIcon,
  CurrencyDollarIcon,
  MenuIcon,
  HandIcon,
  XIcon,
  ScaleIcon,
  ChartBarIcon,
  LockOpenIcon,
  ChartSquareBarIcon,
  CashIcon
} from "@vue-hero-icons/outline";

export default {
  components: {
    CollectionIcon,
    LogoutIcon,
    IdentificationIcon,
    DocumentDuplicateIcon,
    CurrencyDollarIcon,
    HandIcon,
    MenuIcon,
    XIcon,
    ScaleIcon,
    ChartBarIcon,
    ChartSquareBarIcon,
    CashIcon
  },
  created() {
    switch (this.$store.state.server.id) {
      case 1:
        this.serverName = "LB"
        break;
      case 2:
        this.serverName = "UAE"
        break;
      case 3:
        this.serverName = "INTL"
        break;
      case 4:
        this.serverName = "OMN"
        break;
      default:
        break;
    }
  },

  data() {
    return {
      applications: this.$store.state.application,
      isOpen: false,
      serverName: null,
    };
  },
  methods:{
    goToMainPage() {
      router.push("/")
    },
    logout() {
      this.$store.commit("logout");
    }
  }
};
</script>
<style>
.active {
  color: black !important;
}
a {
  color: white !important;
  transition: .5s;
}

a:hover {
  color: black !important;
  text-decoration: none !important;
  transition: .5s;
}
button{
  transition: .5s;
}
button:hover {
  color: white;
  background-color: transparent !important;
  transition: .5s;
}
</style>
