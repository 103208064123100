<template>
  <div>
    <div class="row mt-3">
        <div class="col-12">
          <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Menu Item</label>
        </div>
        <div class="col-12">
          <div class="mb-3 form-check">
            <input type="radio" name="setMenuItem" class="form-check-input" id="newMenuItem" v-model="newMenuItem" :value="true" data-bs-toggle="modal" data-bs-target="#newMenuItemModal" />
            <label class="form-check-label" for="newMenuItem">Create New Menu Item</label>
          </div>
        </div>
      </div>
      <div class="modal fade" id="newMenuItemModal" tabindex="-1" aria-labelledby="newMenuItemModal" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <label class="modal-title subTitle">Create Menu Item</label>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div class="row">
                  <!-- <div class="col-4" v-if="applicationType == 'online'">
                    <div class="mb-3">
                      <label class="form-label">Menu Item Chooser Type<span class="text-danger">*</span></label>
                      <select class="form-select" aria-label="Choose Type" v-model="importedMenuItem">
                        <option :value="true">Existing Menu Item</option>
                        <option :value="false">New Menu Item</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="applicationType == 'online' && importedMenuItem" class="col-4">
                    <div class="mb-3">
                      <label class="form-label">Menu Item <span class="text-danger">*</span></label>
                      <div class="d-inline-flex w-100">
                        <v-select v-if="billMenuItems != null" :options="billMenuItems" label="label" class="vselect w-100" placeholder="Choose Menu Item" v-model="selectedMenuItem">
                          <template slot="option" slot-scope="option" >
                              <span class="pl-4">{{ option.label }}</span>
                          </template>
                        </v-select>
                        <button type="button" class="btn btn-secondary ms-1" @click="getBillMenuItems"><i class="bi bi-arrow-clockwise"></i></button>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="form-label">Menu ID <span class="text-danger">*</span></label>
                      <vue-multi-select style="width: 100%; text-align: start" ref="multiSelect2" v-model="menuItem.menuId" :options="options" :filters="filters" :btnLabel="btnLabel" :selectOptions="menuIds">
                            </vue-multi-select>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="form-label">Type <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="menuItem.type" disabled/>
                    </div>
                  </div>
                  <div class="col-4" v-if="!menuItem.localize">
                    <div class="mb-3">
                      <label class="form-label">Label <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="menuItem.hintFromOldKey" :disabled="importedMenuItem" />
                    </div>
                  </div>
                  <div v-if="!importedMenuItem && menuItem.localize" class="col-12">
                    <AppField :field="menuItem" :stringFields="stringFields" :getStringFields="getStringFields"/>
                  </div>
                  <div class="col-4" v-if="applicationType == 'offline'">
                    <div class="mb-3">
                      <label class="form-label">Application <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="application.title" disabled />
                    </div>
                  </div>
                  <div class="col-4" style="margin: auto 0;">
                    <div class="mb-3 form-check">
                      <input type="checkbox" class="form-check-input" id="menuItemTintable" v-model="menuItem.tintable" :disabled="importedMenuItem">
                      <label class="form-check-label" for="menuItemTintable"> Tintable<span class="text-danger"> *</span></label>
                    </div>
                  </div>
                  <div class="col-4" style="margin: auto 0;">
                    <div class="mb-3 form-check">
                      <input type="checkbox" class="form-check-input" id="menuItemSearchable" v-model="menuItem.searchable" :disabled="importedMenuItem">
                      <label class="form-check-label" for="menuItemSearchable"> Searchable<span class="text-danger"> *</span></label>
                    </div>
                  </div>
                  <div class="col-4" style="margin: auto 0;">
                    <div class="mb-3 form-check">
                      <input type="checkbox" class="form-check-input" id="menuItemLocalize" v-model="menuItem.localize" :disabled="importedMenuItem">
                      <label class="form-check-label" for="menuItemLocalize"> Localize<span class="text-danger"> *</span></label>
                    </div>
                  </div>
                  <div class="col-4" style="margin: auto 0;">
                    <div class="mb-3 form-check">
                      <input type="checkbox" class="form-check-input" id="menuItemRequiresVerification" v-model="menuItem.requiresVerification" :disabled="importedMenuItem">
                      <label class="form-check-label" for="menuItemRequiresVerification"> Requires Verification<span class="text-danger"> *</span></label>
                    </div>
                  </div>
                  <div v-if="importedMenuItem" class="col-4">
                    <label class="form-label mb-0">Image Displayer</label>
                    <img  v-img:name :src="menuItem.imageUrl" style="width: 50px; height: 50px" />
                  </div>
                </div>
                <ImageChooser v-if="!importedMenuItem" :documents="menuItemDocuments" :imageInformation="menuItem" :refreshDocuments="getMenuDocumnets" :isMenu="true"/>
                <div v-if="applicationType == 'online'">
                  <div class="row">
                    <div class="col-4" >
                      <div class="mb-3 form-check">
                        <input type="checkbox" class="form-check-input" id="isMulti" v-model="isMulti" @click="handleMulti($event)">
                        <label class="form-check-label" for="isMulti"> Multi Menu Item<span class="text-danger"> *</span></label>
                      </div>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-4 text-end" v-if="isMulti">
                      <button type="button" class="btn btn-secondary" @click="addToFieldTypeList"><i class="bi bi-plus-circle"></i></button>
                    </div>
                    <div class="col-4" v-if="isMulti">
                    <div class="mb-3">
                      <label class="form-label">Bill Label <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="dataRequest.typeLabel" />
                    </div>
                  </div>
                  <div class="col-4" v-if="isMulti">
                    <label class="form-label">Denomination <span class="text-danger">*</span></label>
                    <div class="mb-3 d-inline-flex w-100">
                    <v-select :options="denominations" label="name" class="vselect w-100" placeholder="Choose Denomination" v-model="dataRequest.denominationId" :reduce="denominationId => denominationId.id">
                        <template slot="option" slot-scope="option">
                            <span class="pl-4">{{ option.id + " - " + option.name }}</span>
                        </template>
                    </v-select>
                    <button type="button" class="btn btn-secondary ms-1" @click="getDenominationBeans('selectedService', true)"><i class="bi bi-arrow-clockwise"></i></button>
                    </div>
                  </div>
                  </div>
                  <FieldTypeData :applicationFieldTypes="inputTypes" :applicationFieldData="dataRequest" :applicationFields="null"/>
                  <div class="row" v-if="isMulti && fieldTypeList.length > 0">
                    <div class="col-12">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Type Label</th>
                            <th>Denomination Id</th>
                            <th>Input Type</th>
                            <th>Hint</th>
                            <th>Retrieve Saved Contact</th>
                            <th>Min Number</th>
                            <th>Max Number</th>
                            <th>Min Character</th>
                            <th>Max Character</th>
                            <th>Wrong Input Hint</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(type, index) in fieldTypeList" :key="index">
                            <td>{{type.typeLabel}}</td>
                            <td>{{type.denominationId}}</td>
                            <td>{{type.type}}</td>
                            <td>{{type.type != "phone" ? type.hint : null}}</td>
                            <td>{{type.type == "phone" ? type.retrieveSavedContact : null}}</td>
                            <td>{{type.type == "numeric" ? type.minNumber : null}}</td>
                            <td>{{type.type == "numeric" ? type.maxNumber : null}}</td>
                            <td>{{type.type == "numeric" ? type.minChar : null}}</td>
                            <td>{{type.type == "numeric" ? type.maxChar : null}}</td>
                            <td>{{type.type == "numeric" ? type.wrongInputHint : null}}</td>
                            <td class="text-center"> 
                              <button type="button" class="btn btn-primary" @click="editField(type)">Edit</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" ref="closeMenuitemModal" data-bs-dismiss="modal" @click="newMenuItem = null">Close</button>
              <button type="button" class="btn btn-primary" @click="createMenuItem">Create Menu Item</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="createdMenuItem != null" class="row">
        <div class="col-12">
          <table class="table table-bordered"> 
            <thead>
              <tr>
                <th>Menu ID</th>
                <th>Type</th>
                <th>Title</th>
                <th>Tintable</th>
                <th>Searchable</th>
                <th>Localize</th>
                <th>Requires Verification</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">{{createdMenuItem.menuId}}</td>
                <td>{{createdMenuItem.type}}</td>
                <td>
                  <span v-if="createdMenuItem.localize">
                    {{menuItem.enHint}}
                  </span>
                  <span v-else>
                    {{ createdMenuItem.label }}
                  </span>
                </td>
                <td class="text-center">
                  <i v-if="createdMenuItem.tintable" class="bi bi-check2 text-success" style="font-size: 25px"></i>
                  <i v-else class="bi bi-x" style="font-size: 30px"></i>
                </td>
                <td class="text-center">
                  <i v-if="createdMenuItem.searchable" class="bi bi-check2 text-success" style="font-size: 25px"></i>
                  <i v-else class="bi bi-x text-danger" style="font-size: 30px"></i>
                </td>
                <td class="text-center">
                  <i v-if="createdMenuItem.localize" class="bi bi-check2 text-success" style="font-size: 25px"></i>
                  <i v-else class="bi bi-x text-danger" style="font-size: 30px"></i>
                </td>
                <td class="text-center">
                  <i v-if="createdMenuItem.requiresVerification" class="bi bi-check2 text-success" style="font-size: 25px"></i>
                  <i v-else class="bi bi-x text-danger" style="font-size: 30px"></i>
                </td>
                <td><img class="m-auto" v-img:name :src="createdMenuItem.iconURL" style="width: 50px; height: 50px" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
import FieldTypeData from "../FieldTypeData.vue";
import AppField from '../ApplicationField.vue';
import ImageChooser from '../ImageChooser.vue';
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"

export default {
    components: {
    vSelect,
    FieldTypeData,
    AppField,
    vueMultiSelect,
    ImageChooser
  },
  data() {
    return {
      checkIfEmpty: commonFunctions.checkIfEmpty,
      DOCUMENT_TYPE_MENU: 2,
      btnLabel: values => `Select (${values.length})`,
      filters: [{
          nameAll: 'Select all',
          nameNotAll: 'Deselect all',
          func() {
              return true;
          },
      }],
      options: {
          multi: true,
          groups: false,
          cssSelected: option => (option.selected ? { 'color': '#E40046' } : ''),
      },
      newMenuItem: false,
      stringFields: null,
      isMulti: false,
      menuItem: {
        label: "Menu Item",
        billLabel: null,
        newHint: false,
        enHint: null,
        arHint: null,
        hintFromOldKey: null,
        type: null,
        newImage: null,
        imageUrl: null,
        imageData: null,
        imageName: null,
        menuId: [],
        localize: false,
        tintable: false,
        requiresVerification: false,
        searchable: false,
      },
      dataRequest: {
        id: null,
        type: null,
        spinnerObject: null,
        applicationFieldTextValueString: null,
        spinnerPrompt: null,
        inputType: null,
        retrieveSavedContact: false,
        minNumber: null,
        maxNumber: null,
        minChar: null,
        maxChar: null,
        wrongInputHint: null,
        radioSpanCount: null,
        hint: null,
        checkboxStylePopup: false,
        dateFormat: "dd-MM-yyyy",
        dependencies: [],
        typeLabel: null,
        denominationId: null
      },
      billMenuItems: null,
      selectedMenuItem: null,
      importedMenuItem: false,
      inputTypes: ["text", "phone", "numeric", "decimal"],
      menuItemDocuments: null,
      createdMenuItem: null,
      showCreateAdminUser: false,
      fieldTypeList: [],
      denominations: null,
      menuIds : [1, 2, 3, 4, 5]
    }
  },
  props: {
    applicationType: String,
    application: Object,
    denomination: Object,
    selectedService: Number
  },
  watch: {
    selectedMenuItem: {
      deep: true,
      handler() {
        this.menuItem.hintFromOldKey = this.selectedMenuItem.label
        this.selectedService = this.selectedMenuItem.serviceId
        this.menuItem.menuId = this.selectedMenuItem.menuId
        this.menuItem.imageUrl = this.selectedMenuItem.iconURL
        this.newMenuItemIcon = false
        this.menuItem.imageName = this.selectedMenuItem.icon
        this.menuItem.searchable = this.selectedMenuItem.searchable
        this.menuItem.tintable = this.selectedMenuItem.tintable
        this.menuItem.requiresVerification = this.selectedMenuItem.requiresVerification
        this.menuItem.localize = this.selectedMenuItem.localize
      }
    },
    showCreateAdminUser() {
      this.$emit('changeShowCreateAdminUser', this.showCreateAdminUser)
    },
  },
  created() {
    if(this.applicationType == "online"){
      this.menuItem.type = "bill"
    } else if(this.applicationType == "offline"){
      this.menuItem.type = "dynamicapp"
    }
    this.getMenuDocumnets("created");
    this.getBillMenuItems();
    this.getStringFields("created");
    this.getDenominationBeans("setMenu");
  },
  methods: {
    handleMulti(event){
      if (this.isMulti){
        if (this.fieldTypeList.length > 0){
          if (confirm("Be careful you will lose your data! Press Okay to continue")) {
            this.dataRequest = this.fieldTypeList.at(0)
            this.fieldTypeList = []
          } else {
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }
    },
    getDenominationBeans(source, forceGet) {
      if (source == 'setMenu' && (forceGet || this.denominations == null || this.denominations.length == 0)) {
        Http()
          .post(ENDPOINTS.denominationBeans, {
            serviceId: this.selectedService,
            includeInactive: true,
          })
          .then((response) => {
            if (response.status) {
              this.denominations = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          })
          .catch((e) => {
            setTimeout(this.getDenominationBeans(source, true), 500);
          });
      }
      return this.denominations;
    },
    getBillMenuItems() {
      Http()
        .get(ENDPOINTS.billMenuItems, {
        })
        .then((response) => {
          if (response.status) {
            this.billMenuItems = response.data;
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    getMenuDocumnets(source, forceGet) {
      if (source == 'created' && (forceGet || this.menuItemDocuments == null || this.menuItemDocuments.length == 0)) {
        Http()
          .post(ENDPOINTS.documents, {
            type: this.DOCUMENT_TYPE_MENU
          })
          .then((response) => {
            if (response.status) {
              this.menuItemDocuments = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          }).catch((e) => {
            setTimeout(this.getMenuDocumnets(source, true), 500);
          });
      }
      return this.menuItemDocuments;
    },
    getApplicationFieldTypes(source, forceGet) {
      this.inputTypes= ["text", "phone", "numeric", "decimal"];
    },
    getApplicationFieldsByApplicationId() {
      if (this.application.id != null) {
        Http()
          .post(ENDPOINTS.applicationFieldsById, {
            applicationId: this.application.id
          })
          .then((response) => {
            if (response.status) {
              this.applicationFields = response.data.sort((a, b) => a.index - b.index);
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          })
          .catch((error) => {
            this.$toast.error("Internal Server Error!")
          })
      }
    },
    getStringFields(source, forceGet) {
      if (source == 'created' && (forceGet || this.stringFields == null || this.stringFields.length == 0)) {
        Http()
          .get(ENDPOINTS.stringFields, {
          })
          .then((response) => {
            if (response.status) {
              this.stringFields = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          }).catch((e) => {
            setTimeout(this.getStringFields(source, true), 500);
          });
      }
      return this.stringFields;

    },
    createMenuItem() {
      if (!this.importedMenuItem) {
        if (this.checkIfEmpty(this.menuItem.menuId)) {
          this.$toast.error("Menu ID Has Invalid Value");
          return;
        }
        if (this.menuItem.localize) {
          if (this.menuItem.newHint && (this.checkIfEmpty(this.menuItem.enHint) || this.checkIfEmpty(this.menuItem.arHint))) {
            this.$toast.error("New Titles Have Invalid Value");
            return;
          }
          if (!this.menuItem.newHint && this.checkIfEmpty(this.menuItem.hintFromOldKey)) {
            this.$toast.error("Title Has Invalid Value");
            return;
          }
        } else if (this.checkIfEmpty(this.menuItem.hintFromOldKey)) {
          this.$toast.error("Label Has Invalid Value");
          return;
        }
        if (this.menuItem.newImage && this.checkIfEmpty(this.menuItem.imageName) && this.checkIfEmpty(this.menuItem.imageData)) {
          this.$toast.error("Image Upload Is Invalid");
          return;
        }
        if (!this.menuItem.newImage && this.checkIfEmpty(this.menuItem.imageUrl)) {
          this.$toast.error("Image Selected Has Invalid Value");
          return;
        }
      }
    /*  if (this.applicationType == 'online') {
        if (this.checkIfEmpty(this.menuItem.dataRequest.type)) {
          this.$toast.error("Input Type Has Invalid Value");
          return;
        }
        if (this.menuItem.dataRequest.type == "text" && this.checkIfEmpty(this.menuItem.dataRequest.hint)) {
          this.$toast.error("Hint Has Invalid Value");
          return;
        }
        if (this.menuItem.dataRequest.type == "numeric" || this.menuItem.dataRequest.type == "decimal") {
          // if (this.checkIfEmpty(this.menuItem.dataRequest.hint)) {
          //   this.$toast.error("Hint Has Invalid Value");
          //   return;
          // }
        }
        if (this.importedMenuItem && this.checkIfEmpty(this.selectedMenuItem.data)) {
          this.$toast.error("Imported Menu Item Has Error In Its Data, Please Contact The Team");
          return;
        }
      }*/

      if (!this.isMulti){
        this.addToFieldTypeList()
      }
      
      var appId;
      if (this.applicationType == "offline"){
        appId = this.application.id
      }
      Http()
        .post(ENDPOINTS.createMenuItem, {
          id: this.importedMenuItem ? this.selectedMenuItem.id : null,
          serviceId: this.applicationType == 'online' ? this.selectedService : null,
          denominationId: this.applicationType == 'online' ? this.denomination.id : null,
          applicationId: this.applicationType == 'offline' ? appId : null,
          data: this.importedMenuItem ? this.selectedMenuItem.data : null,
          typeList: this.fieldTypeList,
          multi: this.isMulti,
          ...this.menuItem,
        })
        .then((response) => {
          if (response.status) {
            this.createdMenuItem = response.data
            this.newMenuItem = false
            this.showCreateAdminUser = true
            this.$refs.closeMenuitemModal.click()
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    addToFieldTypeList() {
      if (this.checkIfEmpty(this.dataRequest.type)) {
        this.$toast.error("Input Type Has Invalid Value");
        return;
      }
      if (this.dataRequest.type == "text" && this.checkIfEmpty(this.dataRequest.hint)) {
        this.$toast.error("Hint Has Invalid Value");
        return;
      }
      if (this.dataRequest.type == "numeric" || this.dataRequest.type == "numeric") {
        if (this.checkIfEmpty(this.dataRequest.hint)) {
          this.$toast.error("Hint Has Invalid Value");
          return;
        }
        if (this.checkIfEmpty(this.dataRequest.minNumber)) {
          this.$toast.error("Min Number Has Invalid Value");
          return;
        }
        if (this.checkIfEmpty(this.dataRequest.maxNumber)) {
          this.$toast.error("Max Number Has Invalid Value");
          return;
        }
        if (this.checkIfEmpty(this.dataRequest.minChar)) {
          this.$toast.error("Min Character Has Invalid Value");
          return;
        }
        if (this.checkIfEmpty(this.dataRequest.maxChar)) {
          this.$toast.error("Max Character Has Invalid Value");
          return;
        }
        if (this.checkIfEmpty(this.dataRequest.wrongInputHint)) {
          this.$toast.error("Wrong Input Hint Has Invalid Value");
          return;
        }
      }
      this.fieldTypeList.push(this.dataRequest)
      this.dataRequest= {
          id: null,
          type: null,
          spinnerObject: null,
          applicationFieldTextValueString: null,
          spinnerPrompt: null,
          inputType: null,
          retrieveSavedContact: false,
          minNumber: null,
          maxNumber: null,
          minChar: null,
          maxChar: null,
          wrongInputHint: null,
          radioSpanCount: null,
          hint: null,
          checkboxStylePopup: false,
          dateFormat: "dd-MM-yyyy",
          dependencies: [],
        }
    },
    editField(field){
      var toRemove = this.fieldTypeList.indexOf(field);
      this.fieldTypeList.splice(toRemove, 1);
      this.dataRequest = field
    }
  }
}
</script>


<style scoped>

.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>