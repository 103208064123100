import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { loader } from './modules/loader'
import router from '../router'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
})
export default new Vuex.Store({
  modules: {
    loader,
  },

  state: {
    privileges: null
  },
  mutations: {
    ['login'](state, payload) {
      for (var element of Object.keys(payload)) {
        if(element == "privileges"){
          continue;
        }
        state[element] = payload[element]
      }
    },
    ['logout'](state) {
      for (var element of Object.keys(state)) {
        if(element == "loader"){
          state.loading = false;
          state.loader.requestsPending = 0;
          continue
        }
        delete state[element]
      }
      if(router.app.$route.name != "Login"){
        router.push("login");
      }
    },
    ['application'](state, payload) {
      state.application = payload
    },
    ["privileges"](state, payload) {
      if (!state.privileges) {
        state.privileges = {};
      }
      for (var element of Object.keys(payload)) {
        state.privileges[element] = payload[element];
      }
    },
    ['multifactor'](state){
      state.user.authenticatorStatus = true
    },
    ['lastRequest'](state){
      state["lastRequest"] = new Date()
    }
  },
  actions: {},
  plugins: [vuexLocal.plugin],
})
