<template>
    <div class="mx-5 py-5" style="text-align: left !important">
        <div class="row">
            <div class="col-12 mt-2">
                <label class="title">School Configurtion</label>
            </div>
            <div class="text-right">
                <button class="btn btn-secondary" type="button" @click="reloadPage">Reset</button>
            </div>
        </div>
        <set-service 
      :applicationType="applicationType" :applicationSettlement="null" 
      :service="service" @changeSelectedService="changeSelectedService" />

      <set-denomination v-if="selectedService" 
      :applicationType="applicationType" :applicationSettlement="null" :denomination="denomination" :selectedService="selectedService"
      @changeShowCreateSchemaPrice="changeShowCreateSchemaPrice" />

      <set-schema-price 
      :selectedService="selectedService" :applicationType="applicationType" :service="service" :showCreateSchemaPrice="showCreateSchemaPrice" :denomination="denomination"
      @changeShowSetSchool="changeShowSetSchool" />

      <set-school v-if="showSetSchool" :denomination="denomination"  />

    </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import SetService from '../components/CreateCollectionApplication/SetService.vue';
import SetDenomination from '../components/CreateCollectionApplication/SetDenomination.vue';
import SetSchemaPrice from '../components/CreateCollectionApplication/SetSchemaPrice.vue';
import SetSchool from '../components/SetSchool.vue';
export default {
    components: {
        SetService,
        SetDenomination,
        SetSchemaPrice,
        SetSchool,
    },
    data() {
        return {
            applicationType: "school",
            selectedService: null,
            service: {
                id: null,
                name: null,
            },
            denomination: {
                id: null,
                name: null,
                newImage: null,
                imageUrl: null,
                imageData: null,
                imageName: null,
                webServerId: null,
                settlement: null,
                autoAcceptTopup: true,
                bulkMultiBillPayment: false,
                commentId: null,
                accountingId: null,
                externalId: null,
                flag: 1,
                receiverAccountId: null
            },
            showCreateSchemaPrice: false,
            showSetSchool: false,
        }
    },
    methods: {
        reloadPage() {
            window.location.reload();
        },
        changeSelectedService(selected) {
            this.selectedService = selected
        },
        changeShowCreateSchemaPrice(selected) {
            this.showCreateSchemaPrice = selected
        },
        changeShowSetSchool(selected) {
            this.showSetSchool = selected
        },
    },
}
</script>

<style>
.title {
    font-size: 30px;
    font-weight: bold;
}

.subTitle {
    font-size: 23px;
    font-weight: 500;
}

.btn-primary {
    background-color: #E40046 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {

    opacity: 1;

}
</style>