<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Set School</label>
            </div>
            <div class="col-6">
                <div class="mb-3 form-check">
                    <input class="form-check-input" type="radio" name="setSchool" id="existingSchool" v-model="newSchool" :value="false" />
                    <label class="form-check-label" for="existingSchool">Existing School</label>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3 form-check">
                    <input type="radio" name="setSchool" class="form-check-input" id="newSchool" v-model="newSchool" :value="true" data-bs-toggle="modal" data-bs-target="#newSchoolModal" />
                    <label class="form-check-label" for="newSchool">New School</label>
                </div>
            </div>
        </div>
        <div v-if="newSchool != null && !newSchool">
            <div class="row">
                <div class="col-4 d-inline-flex">
                    <v-select :options="schools" label="name" class="vselect w-100" placeholder="Choose School"  v-model="school.id" :reduce="id => id.id">
                        <template slot="option" slot-scope="option">
                            <span class="pl-4">{{ option.id + " - " +option.name }}</span>
                        </template>
                    </v-select>
                    <button type="button" class="btn btn-secondary ms-1" @click="getSchools('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
                </div>
                <div class="col-4">
                    <button type="button" class="btn btn-primary" @click="confirmSchool" :disabled="school.id == null">Confirm</button>
                </div>
            </div>
        </div>
        <div class="modal fade" id="newSchoolModal" tabindex="-1" aria-labelledby="newSchoolModal" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <label class="modal-title subTitle">Create School</label>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label class="form-label">Name <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" v-model="school.name" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label class="form-label">Abbreviation</label>
                                        <input type="text" class="form-control" v-model="school.abbreviation" />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="mb-3">
                                        <label class="form-label">Order Index <span class="text-danger">*</span></label>
                                        <input type="number" class="form-control" v-model="school.orderIndex" />
                                    </div>
                                </div>
                                <div class="col-4" style="margin: auto 0;">
                                    <div class="mb-3 form-check">
                                        <input type="checkbox" class="form-check-input" id="studentCardSupported" v-model="school.studentCardSupported">
                                        <label class="form-check-label" for="studentCardSupported">Student Card Supported<span class="text-danger"> *</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="closeSchoolModal" data-bs-dismiss="modal" @click="newSchool = false">Close</button>
                        <button type="button" class="btn btn-primary" @click="createSchool" >Create School</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Http, { ENDPOINTS } from "../request";
import {commonFunctions} from "../helpers/CommonFunctions"
export default {
    components:{
        vSelect,
    },
    props: {
        denomination: Object,
    },
    data() {
        return {
            checkIfEmpty: commonFunctions.checkIfEmpty,
            schools: [],
            newSchool: false,
            school:{
                id: null,
                name: null,
                abbreviation: null,
                orderIndex: null,
                studentCardSupported: true
            }
        }
    },
    created(){
        this.getSchools("created", true);
    },
    methods: {
        getSchools(source, forceGet){ 
            Http()
                .post(ENDPOINTS.schoolBeans, {
                })
                .then((response) => {
                    if (response.status) {
                        this.schools = response.data.list;
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((e) => {
                    setTimeout(this.getSchools(source, true), 500);
                });
        },
        createSchool(){ 
            if (this.checkIfEmpty(this.school.name)) {
                this.$toast.error("Name Has Invalid Value");
                return;
            }
            if (this.checkIfEmpty(this.school.orderIndex)) {
                this.$toast.error("Order Index Has Invalid Value");
                return;
            }
            Http()
                .post(ENDPOINTS.createSchool, {
                    ...this.school
                })
                .then((response) => {
                    if (response.status) {
                        this.school = response.data;
                        this.schools.push({id : response.data.id, name: response.data.name})
                        this.$refs.closeSchoolModal.click()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                            this.$toast.success("Please Confirm This Configuration To Selected Denomination")
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        confirmSchool(){
            if (this.checkIfEmpty(this.school.id)) {
                this.$toast.error("School Has Invalid Value");
                return;
            }
            if (this.checkIfEmpty(this.denomination.id)) {
                this.$toast.error("Denomination Has Invalid Value");
                return;
            }
            Http()
                .post(ENDPOINTS.confirmSchool, {
                    schoolId: this.school.id,
                    denominationId: this.denomination.id
                })
                .then((response) => {
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        }
    }
}
</script>

<style scoped>
.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>