<template>
    <div class="pb-5">
        <div class="mt-5 px-5">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                        Uploaded Payments
                    </h2>
                    <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-sm text-sm float-right" :data="data" :columns="columns" :file-name="'Payments ' + (new Date()).getTime()" :file-type="'xlsx'" :sheet-name="'sheetname'">
                            Export
                    </vue-excel-xlsx>
                    <button class="appearance-none  bg-woo shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="getUploadedPayments">Search</button>
                    <button class="appearance-none  bg-secondary shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="getUploadedPaymentConfigration">Upload Payment</button>
                    <button class="appearance-none  bg-secondary shadow text-white rounded py-2 px-4  leading-tight float-right me-2" @click="bulkDeletePayment">Bulk Delete</button>
                </div>
            </div>
            <div class="row mt-4 text-left">
                <div class="col-4">
                    <label >Denomination:</label>
                    <v-select :options="denominations" label="name" class="vselect w-100" placeholder="Choose Denomination" v-model="denominationId" :reduce="denominationId => denominationId.id">
                        <template slot="option" slot-scope="option">
                            <span class="pl-4">{{ option.id + " - " + option.name }}</span>
                        </template>
                    </v-select>
                </div>
                <div class="col-4">
                    <label>Date From:</label>
                    <date-time-picker  v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom"></date-time-picker>
                </div>
                <div class="col-4">
                    <label>Date To:</label>
                    <date-time-picker  v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
                </div>
            </div>
        </div>
        <GoodTable v-if="data != null && data.length != 0" :name="'Online Payments'" :rows="data" :columns="columns"
            :line-numbers="true" :method1="openEditModal"  @selectedRows="getSelectedRows" />
        <div v-else class="mt-32">
            <div
                class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
                <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
                <p class="text-3xl">No Data Found!</p>
            </div>
        </div>
        <div class="modal fade" id="editPaymentStatus" tabindex="-1" ref="editPaymentStatusModal" >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <label class="modal-title subTitle">Edit Payment Status</label>
                  <button type="button" class="btn-close" @click="closeEditModal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-6 text-left">
                        <div class="mb-3">
                          <label class="form-label">Change Status <span class="text-danger">*</span></label>
                          <v-select :options="statuses" label="name" class="vselect w-100" placeholder="Choose Status" v-model="status" :reduce="status => status.id">
                            <template slot="option" slot-scope="option">
                                <span class="pl-4">{{ option.name }}</span>
                            </template>
                        </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeEditModal">Close</button>
                  <button type="button" class="btn btn-primary"  @click="editPayment">Submit</button>
                </div>
              </div>
            </div>
          </div>
        <div class="modal fade" id="editPaymentStatus" tabindex="-1" ref="uploadPaymentModal" >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <label class="modal-title subTitle">Add Payment</label>
                  <button type="button" class="btn-close" @click="closeUploadModal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-4 text-left" v-for="(configuration, index) in configurations" :key="index">
                        <div class="mb-3">
                            <label class="form-label">{{ configuration.identifier }} <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="configuration.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeUploadModal">Close</button>
                  <button type="button" class="btn btn-primary"  @click="uploadPayment">Submit</button>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>

<script>

import Http, { ENDPOINTS } from "../../request";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import GoodTable from "../../components/GoodTable.vue";
import DateTimePicker from 'vue-vanilla-datetime-picker';
import '../../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css'
import { ExclamationIcon } from "@vue-hero-icons/outline";
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
    data(){
        return {
            checkIfEmpty: commonFunctions.checkIfEmpty,
            formatNumber: commonFunctions.formatNumber,
            data: null,
            denominations: [],
            denominationId: null,
            dateFrom: '',
            dateTo: '',
            columns:[
                {
                    label: "Amount",
                    field: "amount",
                    type: "number",
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Amount",
                        filterValue: "",
                    }
                },
                {
                    label: "Amount Paid",
                    field: "amountPaid",
                    type: "number",
                    formatFn: f => (this.formatNumber(f)),
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Amount Paid",
                        filterValue: "",
                    }
                },
                // {
                //     label: "Currency",
                //     field: "currency",
                //     filterOptions: {
                //         enabled: true,
                //         placeholder: "Search by Currency",
                //         filterValue: "",
                //     }
                // },
                {
                    label: "Status",
                    field: "status",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search by Status",
                        filterValue: "",
                    }
                },
                {
                    label: "Date Creation",
                    field: "dateCreation",
                },
                {
                    label: "Date Modification",
                    field: "dateModification",
                },
            ],
            selectedPayment: null,
            statuses: [
                {
                    id: 1,
                    name: "Pending"
                },
                {
                    id: 2,
                    name: "Paid"
                },
                {
                    id: 3,
                    name: "Partially Paid"
                },
                {
                    id: 4,
                    name: "Error"
                },
                {
                    id: 5,
                    name: "Deleted"
                }
            ],
            status: null,
            configurations: null,
            rowsSelected: null,
        }
    },
    components:{
        vSelect,
        ExclamationIcon,
        DateTimePicker,
        GoodTable,
    },
    created(){
        this.getDenominations("created", true)
        this.filtersHandler();
    },
    methods: {
        filtersHandler() {
            this.dateTo = new Date();
            this.dateFrom = new Date(this.dateTo - 1);
            this.dateTo.setHours(23, 59, 59, 59);
            this.dateFrom.setHours(0, 0, 0, 0);
        },
        getSelectedRows(value) {
            this.rowsSelected = value;
        },
        getDenominations(source, forceGet) {
            if (source == 'created' && (forceGet || this.denominations == null || this.denominations.length == 0)) {
                Http()
                    .get(ENDPOINTS.userDenominations, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.denominations = response.data;
                            if(response.data.length == 1){
                                this.denominationId = response.data.at(0).id
                            }
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    })
                    .catch((e) => {
                        setTimeout(this.getDenominations(source, true), 500);
                    });
            }
            return this.denominations;
        },
        getUploadedPayments() {
            Http()
                .post(ENDPOINTS.uploadedPayments, {
                    denominationId: this.denominationId,
                    dateTo: new Date(this.dateTo).getTime(),
                    dateFrom: new Date(this.dateFrom).getTime(),
                })
                .then((response) => {
                    if (response.status) {
                        this.data = response.data;
                        this.data.forEach(element => {
                            element.extraFields.forEach(extra => {
                                var title = extra.title
                                var value = extra.value
                                element[title] = value
                                if (!this.findObjetInArray(title)){
                                    var obj = {
                                    "label": title,
                                    "field": title,
                                    filterOptions: {
                                        enabled: true,
                                        placeholder: "Search by " + title,
                                        filterValue: "",
                                    },
                                }
                                this.columns.unshift(obj)
                                }
                            })
                            var keyLabel = element.referenceLabel
                            element[keyLabel] = element.reference
                            if (!this.findObjetInArray(keyLabel)){
                                var obj = {
                                    "label": keyLabel,
                                    "field": keyLabel,
                                    filterOptions: {
                                        enabled: true,
                                        placeholder: "Search by " + keyLabel,
                                        filterValue: "",
                                    },
                                }
                                this.columns.unshift(obj)
                            }
                            if (!this.findObjetInArray("actions")) {
                                var obj = {
                                    "label": "Actions",
                                    "field": "actions",
                                    actionName: "Edit",
                                }
                                this.columns.push(obj)
                            }
                        })
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Something Went Wrong");
                });
        },
        openEditModal(payment){
            this.selectedPayment = payment
            this.$refs.editPaymentStatusModal.classList.add('show'); 
            this.$refs.editPaymentStatusModal.style.display = 'block'; 
        },
        closeEditModal(){
            this.$refs.editPaymentStatusModal.classList.remove('show'); 
            this.$refs.editPaymentStatusModal.style.display = 'none'; 
        },
        closeUploadModal(){
            this.$refs.uploadPaymentModal.classList.remove('show'); 
            this.$refs.uploadPaymentModal.style.display = 'none'; 
        },
        editPayment(){
            if(this.checkIfEmpty(this.selectedPayment)){
                this.$toast.error("Payment Is Invalid")
                return;
            }
            if (this.checkIfEmpty(this.status)){
                this.$toast.error("Status Is Invalid")
                return;
            }
            Http()
                .post(ENDPOINTS.editPaymentStatus, {
                    id: this.selectedPayment.id,
                    status: this.status
                })
                .then((response) => {
                    if (response.status) {
                        this.selectedPayment.status = this.status == 2 ? "Payed" : "Deleted"
                        this.closeEditModal()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
        },
        getUploadedPaymentConfigration(){
            if(this.checkIfEmpty(this.denominationId)){
                this.$toast.error("Please Select A Denomination")
                return 
            }
            Http()
                .post(ENDPOINTS.uploadedPaymentConfiguration, {
                    denominationId: this.denominationId,
                })
                .then((response) => {
                    if (response.status) {
                        this.configurations = response.data
                        this.$refs.uploadPaymentModal.classList.add('show');
                        this.$refs.uploadPaymentModal.style.display = 'block';
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
        },
        uploadPayment(){
            Http()
                .post(ENDPOINTS.uploadOnePayment, {
                    denominationId: this.denominationId,
                    payments: this.configurations,
                })
                .then((response) => {
                    if (response.status) {
                        this.closeUploadModal()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
        },
        bulkDeletePayment(){
            if(confirm("Are you sure you want to delete the selected rows")){
                Http()
                .post(ENDPOINTS.bulkDeletePayments, {
                    payments: this.rowsSelected.map(m => m.id),
                })
                .then((response) => {
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
            }
        },
        findObjetInArray(title){
            return this.columns.find(f => f.field == title)!=undefined
        },
    }
}
</script>

<style scoped>

.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}
</style>