<template>
  <div class="row mt-4">
    <div class="col-3">
        <button class="btn btn-secondary" type="button" @click="refreshApplications">Refresh Applications</button>
    </div>
    <div class="col-3 text-center">
        <button class="btn btn-secondary" type="button" @click="refreshDenominations">Refresh Denominations</button>
    </div>
    <div class="col-3 text-center">
        <button class="btn btn-secondary" type="button" @click="refreshStrings">Refresh Strings</button>
    </div>
    <div class="col-3 text-right">
        <button class="btn btn-secondary" type="button" data-bs-toggle="modal" data-bs-target="#updateModal">Update Account Version</button>
    </div>
    <div class="modal fade" id="updateModal" tabindex="-1" aria-labelledby="updateModal" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                    <label class="modal-title subTitle">Update Account Version</label>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12 text-left">
                                    <div class="mb-3">
                                        <label class="form-label">Account ID <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" v-model="accountId" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" ref="closeUpdateModal" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary"  @click="updateAccountVersion">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
    data(){
        return{
            checkIfEmpty: commonFunctions.checkIfEmpty,
            accountId: 10100000,
        }
    },
    methods: {
        refreshApplications(){
            Http()
                .get(ENDPOINTS.refreshApplications)
                .then((response) => {
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        refreshDenominations(){
            Http()
                .get(ENDPOINTS.refreshDenominations)
                .then((response) => {
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        refreshStrings(){
            Http()
                .get(ENDPOINTS.refreshStrings)
                .then((response) => {
                    if (response.status) {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
        updateAccountVersion(){
            if (this.checkIfEmpty(this.accountId)){
                this.$toast.error("Please Fill Account Id")
                return;
            }
            Http()
                .post(ENDPOINTS.updateAccountVersion, {
                    accountsIds: [this.accountId]
                })
                .then((response) => {
                    if (response.status) {
                        this.$refs.closeUpdateModal.click()
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        }
    }
}
</script>

<style>
.btn-primary{
  background-color: #E40046!important;
}
</style>