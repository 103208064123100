<template lang="">
  <div class="row" v-if="field != null">
    <div class="col-4">
      <div class="mb-3">
        <label class="form-label">{{field.label}} Hint Chooser Type <span class="text-danger">*</span></label>
        <select class="form-select" aria-label="Choose Type" v-model="field.newHint">
          <option selected disabled :value="null">Choose Type</option>
          <option :value="false">Existing Title</option>
          <option :value="true">New Title</option>
        </select>
      </div>
    </div>
    <div v-if="field.newHint != null && field.newHint" class="col-4">
      <div class="mb-3">
        <label class="form-label">Title - En <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="field.enHint" />
      </div>
    </div>
    <div v-if="field.newHint != null && field.newHint" class="col-4">
      <div class="mb-3">
        <label class="form-label">Title - Ar <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="field.arHint" />
      </div>
    </div>
    <div v-if="field.newHint != null && !field.newHint" class="col-4">
      <div class="mb-3">
        <label class="form-label">Title <span class="text-danger">*</span></label>
        <div class="d-inline-flex w-100">
          <v-select :options="stringFields == null ? [] : stringFields" label="value" class="vselect w-100" placeholder="Choose Title" v-model="field.hintFromOldKey" :reduce="hintFromOldKey => hintFromOldKey.title" >
            <template slot="option" slot-scope="option" >
                <span class="pl-4">{{ option.value }}</span>
            </template>
          </v-select>
          <button type="button" class="btn btn-secondary ms-1" @click="getStringFields('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components:{
    vSelect
  },
  data () {
    return {
    }
  },
  props: {
    stringFields: Array,
    field: Object,
    getStringFields: Function
  },
};
</script>
<style>
button:disabled {
  pointer-events: none !important;
  opacity: 0.2;
}
</style>
