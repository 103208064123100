<template>
  <div
    id="login"
    class="bg-white flex items-center justify-center h-screen w-screen back"
  >
    <form class="box login text-center">
      <div class="content px-8 py-12 rounded-b border border-1 border-solid">
        <img src="../assets/images/whish_v2-removebg-preview.png" />
        <label
          for
          class="block w-full text-gray-800 text-2xl label"
          style="font-size: 150%"
          >Username*</label
        >
        <input
          spellcheck="false"
          name="userName"
          v-model="userName"
          v-focus
          style="font-size: 150%"
          class="text-4xl font-bold text-transform-none w-full h-12 mt-2 mb-1 bg-gray-light rounded p-3 focus:outline-none focus:bg-white text-black focus:border-blue-800 border-transparent border-2 border-black"
        />
        <label
          for
          class="block w-full text-gray-800 text-2xl label mt-3"
          style="font-size: 150%"
          >Password*</label
        >
        <div
          class="flex flex-row text-transform-none h-12 mt-2 mb-1 bg-white rounded"
        >
          <input
            name="password"
            type="password"
            v-model="password"
            style="font-size: 150%"
            class="text-4xl text-transform-none w-full bg-gray-light rounded p-3 focus:outline-none focus:bg-white text-black focus:border-blue-800 border-transparent border-2 border-black"
          />
        </div>
        <label
          for
          class="block w-full text-gray-800 text-2xl label mt-3"
          style="font-size: 150%"
          >OTP</label
        >
        <input
          spellcheck="false"
          name="otp"
          v-model="otp"
          v-focus
          style="font-size: 150%"
          class="text-4xl font-bold text-transform-none w-full h-12 mt-2 mb-1 bg-gray-light rounded p-3 focus:outline-none focus:bg-white text-black focus:border-blue-800 border-transparent border-2 border-black"
        />
        <label
          for
          class="block w-full text-gray-800 text-2xl label mt-3"
          style="font-size: 150%"
          >Currency*</label
        >
        <select
          spellcheck="false"
          name="serverId"
          v-model="serverId"
          v-focus
          style="font-size: 150%"
          class="text-4xl font-bold text-transform-none w-full h-12 mt-2 mb-1 bg-gray-light rounded py-1 px-3 focus:outline-none focus:bg-white text-black focus:border-blue-800 border-transparent border-2 border-black"
        >
        <option value=1>LEBANON (LBP)</option>
        <option value=2>UNITED ARAB EMIRATES (AED)</option>
        <option value=3>INTERNATIONAL (USD)</option>
        </select>
        <button
          :disabled="userName.length == 0 || password.length == 0 || serverId.length == 0"
          @click.prevent="mainPage()"
          type="submit"
          class="w-full h-12 mt-2 rounded-sm bg-button label text-whiteText text-xs"
        >
          SIGN IN
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Http, { ENDPOINTS } from "../request";
import store from "../store/";
import {commonFunctions} from "../helpers/CommonFunctions"

export default {
  data() {
    return {
      userName: "",
      password: "",
      serverId: 1,
      otp: '',
      attempt: true,
      getPrivilges: commonFunctions.getPrivilges
    };
  },
  created() {
  
  },
  methods: {
    mainPage() {
      this.attempt = false;
      Http()
        .post(ENDPOINTS.login, {
          username: this.userName,
          password: this.password,
          serverId: this.serverId,
          otp: this.otp
        }, {
          headers:{
            serverid: this.serverId
          }
        })
        .then((response) => {
          if (response.status) {
            store.commit("login", response.data);
            var privileges = this.getPrivilges(response.data.privileges.map(m => m.privilege));
            store.commit("privileges", privileges);
            this.$emit("loggedIn");
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            this.$store.commit("logout");
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error("Internal Server Error!")
        })
    },
    
  },
};
</script>
<style scoped>
.login:hover {
  box-shadow: 1px 1px #000000, 2px 2px #000000, 3px 3px #04080a;
}

.login {
  transition: 0.5s;
  background: #fff;
}
</style>
