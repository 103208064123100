<template>
    <div class="m-5 p-5 pt-0" style="text-align: left !important">
        <div class="row">
            <div class="col-12 text-center mt-2">
                <label class="title">Upload Payments Configurations</label>
            </div>
            <div class="text-right">
                <button class="btn btn-secondary" type="button" @click="reloadPage">Reset</button>
            </div>
        </div>
    <set-service 
      :applicationType="applicationType" :applicationSettlement="applicationSettlement" 
      :service="service" @changeSelectedService="changeSelectedService" />

      <set-denomination v-if="selectedService" 
      :applicationType="applicationType" :applicationSettlement="applicationSettlement" :denomination="denomination" :selectedService="selectedService"
      @changeShowCreateSchemaPrice="changeShowCreateSchemaPrice" />

      <set-schema-price 
      :selectedService="selectedService" :applicationType="applicationType" :service="service" :showCreateSchemaPrice="showCreateSchemaPrice" :denomination="denomination"
      @changeShowSetFileConfigurations="changeShowSetFileConfigurations" />
        
      <div v-if="showSetFileConfigurations">
            <div class="row mt-3">
                <div class="col-12">
                    <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>File Configurations</label>
                </div>
                <div class="col-12">
                    <label class="subTitle mb-3" style="font-size: 20px;"><i class="bi bi-dot me-2"></i>Columns</label>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Identifier <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="column.identifier" />
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Description <span class="text-danger">*</span></label>
                        <v-select :options="descriptions" name="label" class="vselect w-100" placeholder="Choose Description" v-model="column.description">
                            <template slot="option" slot-scope="option">
                                <span class="pl-4">{{ option.label }}</span>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Order <span class="text-danger">*</span></label>
                        <input type="number" class="form-control" v-model="column.order" />
                    </div>
                </div>
                <div class="col-12">
                    <application-field  :field="column" :stringFields="stringFields" :getStringFields="getStringFields"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-end mb-3">
                    <button type="button" class="btn btn-secondary ms-1" @click="addColumn"><i class="bi bi-plus-circle"></i></button>
                </div>
            </div>
            <div class="row" v-if="columns.length > 0">
                <div class="col-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Identifier</th>
                                <th>Description</th>
                                <th>Label</th>
                                <th>Order</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(c, index) in columns" :key="index">
                                <td>{{c.identifier}}</td>
                                <td>{{c.description}}</td>
                                <td>{{c.newHint ? c.enHint : c.hintFromOldKey}}</td>
                                <td>{{c.order}}</td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-primary" @click="editColumn(c)">Edit</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-end">
                    <vue-excel-xlsx v-if="showExcelButton"
                    class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center shadow-sm text-sm"
                    :data="excelData" :columns="excelColumns" :file-name="'Payments'"
                    :file-type="'xlsx'" :sheet-name="'sheetname'">
                    Export
                    </vue-excel-xlsx>
                    <button type="button" class="btn btn-secondary ms-3" @click="submitButton">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ApplicationField from '../../components/ApplicationField.vue';
import SetDenomination from '../../components/CreateCollectionApplication/SetDenomination.vue';
import SetSchemaPrice from '../../components/CreateCollectionApplication/SetSchemaPrice.vue';
import SetService from '../../components/CreateCollectionApplication/SetService.vue';
import Http, { ENDPOINTS } from "../../request";

export default {
  components: { 
    ApplicationField, 
    vSelect,
    SetService,
    SetDenomination,
    SetSchemaPrice
 },
    data() {
        return {
            applicationType: "offline",
            applicationSettlement: "Whish Money",
            selectedService: null,
            service: {
                id: null,
                name: null,
            },
            denomination: {
                id: null,
                name: null,
                newImage: null,
                imageUrl: null,
                imageData: null,
                imageName: null,
                webServerId: null,
                settlement: null,
                autoAcceptTopup: true,
                bulkMultiBillPayment: false,
                commentId: null,
                accountingId: null,
                externalId: null
            },
            application: {
                id: null,
                title: null,
                tags: [],
            },
            showCreateSchemaPrice: false,
            column: {
                identifier: null,
                label: "Label",
                newHint: false,
                hintFromOldKey: null,
                enHint: null,
                arHint: null,
                description: null,
                order: 0
            },
            columns: [],
            stringFields: null,
            descriptions: ["Key", "Amount", "Currency", "Other"],
            showSetFileConfigurations: false,
            showExcelButton: false,
            excelColumns: [],
            excelData: []
        }
    },
    watch: {
    },
    created(){
        this.getStringFields("created");
    },
    methods: {
        changeSelectedService(selected) {
            this.selectedService = selected
        },
        changeShowCreateSchemaPrice(selected) {
            this.showCreateSchemaPrice = selected
        },
        changeShowSetFileConfigurations(selected) {
            this.showSetFileConfigurations = selected
        },
        getStringFields(source, forceGet) {
            if (source == 'created' && (forceGet || this.stringFields == null || this.stringFields.length == 0)) {
                Http()
                    .get(ENDPOINTS.stringFields, {
                    })
                    .then((response) => {
                        if (response.status) {
                            this.stringFields = response.data;
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.success(response.dialog.message)
                            }
                        } else {
                            if (response.dialog != null && response.dialog.message != null) {
                                this.$toast.error(response.dialog.message)
                            }
                        }
                    }).catch((e) => {
                        setTimeout(this.getStringFields(source, true), 500);
                    });
            }
            return this.stringFields;
        },
        editColumn(col) {
            var old = this.columns.filter(f => f == col)
            this.column = {
                identifier: col.identifier,
                label: col.label,
                newHint: col.newHint,
                hintFromOldKey: col.hintFromOldKey,
                enHint: col.enHint,
                arHint: col.arHint,
                description: col.description,
                order: col.order
            }
            const index = this.columns.indexOf(old[0]);
            if (index > -1) {
                this.columns.splice(index, 1);
            }
            this.columns.sort((a, b) => a.order - b.order)
        },
        reloadPage() {
            window.location.reload();
        },
        addColumn() {
            this.columns.push(this.column)
            this.column = {
                identifier: null,
                label: "Label",
                newHint: false,
                hintFromOldKey: null,
                enHint: null,
                arHint: null,
                description: null,
                order: 0
            }
            this.columns.sort((a, b) => a.order - b.order)
        },
        submitButton(){
            Http()
                .post(ENDPOINTS.uploadPaymentsConfigurations, {
                    denominationId: this.denomination.id,
                    configurations: this.columns
                })
                .then((response) => {
                    if (response.status) {
                        for (var i = 0; i < this.columns.length; i++) {
                            var identifier = this.columns.at(i).identifier;
                            this.excelColumns.push({
                                "label": identifier,
                                "field": identifier
                            })
                            var obj = {}
                            obj[identifier] = ""
                            this.excelData.push(obj)
                        }
                        this.showExcelButton = true
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.success(response.dialog.message)
                        }
                    } else {
                        if (response.dialog != null && response.dialog.message != null) {
                            this.$toast.error(response.dialog.message)
                        }
                    }
                })
                .catch((error) => {
                    this.$toast.error("Internal Server Error!")
                })
        },
    }
}
</script>

<style scoped>
.title {
    font-size: 30px;
    font-weight: bold;
}

.subTitle {
    font-size: 23px;
    font-weight: 500;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
.btn-primary{
    background-color: #E40046!important;
}

</style>