<template lang="">
  <div class="mx-5">
    <div class="row my-3">
      <div class="col-4 text-start">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate" >
        {{ this.label.replaceAll(".", " ").replaceAll("title", "").toUpperCase() }}
        </h2>
      </div>
      <div class="col-8 text-end d-inline-flex justify-end">
        <button v-if="isIskan" class=" mx-2 bg-woo shadow text-white rounded py-2 px-4 leading-tight" @click="returnPdfs">Pdf</button>
        <button class=" mx-2  bg-woo shadow text-white rounded py-2 px-4 leading-tight" @click="filtersHandler">Search</button>
        <vue-excel-xlsx class="bg-green-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded items-center shadow-sm text-sm ms-2"
        :data="data" :columns="columnsExport" :file-name="'Application Submissions ' + (new Date()).getTime()" :file-type="'xlsx'" :sheet-name="'sheetname'">
        Export
      </vue-excel-xlsx>
      </div>
    </div>
    <div class="row">
      <div class="col-3" v-if="showStatus">
        <select class="form-select" aria-label="Select Status" v-model="statusSelected" @change="filtersHandler">
          <option selected value="All">All</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
        </select>
      </div>
      <div v-else class="col-3"></div>
      <div class="col-3">
        <input type="text" class="form-control"  v-model="searchKey" @change="searchHandler" @keyup="searchHandler"  placeholder="Search"  />
      </div>
      <div class="col-3 d-inline-flex app">
        <label style="margin: auto 5px;">From:</label>
        <date-time-picker v-model="dateFrom" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateFrom" ></date-time-picker>
      </div>
      <div class="col-3 d-inline-flex app">
        <label style="margin: auto 5px;">To:</label>
        <date-time-picker v-model="dateTo" format="dd-LL-yyyy HH:mm:ss" :initial-view-date="this.dateTo"></date-time-picker>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 mt-2">
        <div class="form-check form-switch float-end" style="margin: auto 10px">
          <input class="form-check-input" type="checkbox" role="switch" id="cardsSwitch" v-model="showCards">
          <label class="form-check-label" for="cardsSwitch" v-if="showCards">Cards</label>
          <label class="form-check-label" for="cardsSwitch" v-else>Table</label>
        </div>
        <div class="form-check float-end" style="margin: auto 10px">
          <input class="form-check-input" type="checkbox" id="includeReversed" v-model="includeReversed">
          <label class="form-check-label" for="includeReversed">Include Reversed</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="data.length">
          <div v-if="showCards">
            <div class="mx-4 my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center">
              <div v-for="item in dataTemp" :key="item.index">
                <ApplicationCardVue @patchApplication="patchApplications" @editDepositorName="editDepositorName"
                 :receivedData="item" :typeId="typeId" :appSubmissionId="typeId" 
                 :allowAcceptReject="allowAcceptReject" :allowEditDepositor="allowEditDepositor" :showStatus="showStatus" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
                <div class="col-12 d-flex justify-center">
                  <button type="button" class="btn btn-light btn-sm mx-2" @click="paginationChange <= 1 ? paginationChange : paginationChange--"><i class="bi bi-chevron-left"></i></button>
                  <label style="margin: auto 0">Page {{paginationChange}}</label>
                  <button type="button" class="btn btn-light btn-sm mx-2" @click="paginationChange++" ><i class="bi bi-chevron-right"></i></button>
                  <select class="form-select" aria-label="Per Page" v-model="perPage" style="width: 100px">
                    <option :value="10">10</option>
                    <option selected :value="50">50</option>
                    <option :value="100">100</option>
                    <option :value="500">500</option>
                    <option :value="25000">25000</option>
                    <option :value="null">All</option>
                  </select>
                </div>
            </div>
            <ApplicationsTable :receivedData="dataTemp" :cols="columnsExport" :typeId="typeId" :imgs="imgs" @selectedRows="getSelectedRows" />
          </div>
        </div>
        <div v-else class="mt-3">
          <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md" >
            <ExclamationIcon class="w-32 h-32 text-gray-900"></ExclamationIcon>
            <p class="text-3xl">No Applications Found!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import ApplicationCardVue from "../components/ApplicationCard.vue";
import ApplicationsTable from "../components/ApplicationsTable.vue";
import JwPagination from "jw-vue-pagination";
import Http, { ENDPOINTS } from "../request";
import VueTailwind from "vue-tailwind";
import { ExclamationIcon } from "@vue-hero-icons/outline";
import DateTimePicker from 'vue-vanilla-datetime-picker';
import  '../../node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker.css'
import PDFMerger from 'pdf-merger-js/browser';
import { saveAs } from 'file-saver' 

Vue.use(VueTailwind);

export default {
  props: ['label', 'typeId', 'columnsExport', 'allowAcceptReject', 'allowEditDepositor', 'showStatus'],

  components: {
    ApplicationsTable,
    ApplicationCardVue,
    DateTimePicker,
    ExclamationIcon,
  },

  data() {
    return {
      dataExport: [],
      dateFrom: '',
      dateTo: '',
      statusObj: {
        1: "Pending",
        4: "Approved",
        5: "Rejected",
      },
      // renderComponent: true,
      paginationChange: 1,
      today: null,
      tomorrow: null,
      data: [],
      dataTemp: [],
      perPage: 50,
      searchKey: null,
      statusSelected: "Pending",
      imgs: [],
      showCards: false,
      rowsSelected:[],
      isIskan: false,
      includeReversed: true,
      // for initial date
    };
  },

  created() {
    this.isIskan = this.typeId == 62
    this.dateTo = new Date();
    this.dateFrom = new Date(this.dateTo - 1);
    this.dateTo.setHours(23,59,59,59);
    this.dateFrom.setHours(0,0,0,0);
    this.getApplications(1);
  },
  watch: {
    paginationChange(){
      this.filtersHandler()
    },
    perPage(){
      this.paginationChange = 1
      this.filtersHandler()
    },
    label: 'handleEmptyParamas',
    typeId: 'handleEmptyParamas',
    columnsExport: 'handleEmptyParamas',
    allowAcceptReject: 'handleEmptyParamas',
    allowEditDepositor: 'handleEmptyParamas',
    showStatus: 'handleEmptyParamas',
  },
  methods: {
    handleEmptyParamas(){
      if (this.label == null || this.typeId == null || this.columnsExport == null || this.allowAcceptReject == null || this.allowEditDepositor == null || this.allowEditDepositor == null || this.showStatus == null) {
        this.$router.push({ name: 'Applications' });
      }
    },
    getSelectedRows(value){
      this.rowsSelected = value;
    },
    async returnPdfs() {
      var jsonData = {
        "body": {
          "stampNote": "طابع مالي بقيمة ٢٥٠ ل.ل",
          "printCount": null,
          "header": "",
          "uploadReceiptTransactionId": "",
          "copies": null,
          "automaticPrint": true,
          "mandatoryPrint": true,
          "titleNoteEnglish": "",
          "titleNoteArabic": "",
          "type": "MULIT_RECEIPT",
          "receipts": []
        }
      }
      Http()
        .post(ENDPOINTS.getJsonReceipts, {
          transactionIds: this.rowsSelected.filter(f => f.transactionId != null).map(m => m.transactionId),
        })
        .then((response) => {
          jsonData.body["receipts"] = response.data
          let file = new Blob([JSON.stringify(jsonData)], { type: 'text/plain;charset=utf-8' });
          saveAs(file, this.label.replaceAll(".", "").replaceAll("title", "") + new Date().getTime())
          if (response.status) {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    // async returnPdfs() {
    //   store.dispatch('loader/pending')
    //   var pdfs = await this.sendPdf();
    //   this.createPdf(pdfs)
    //   store.dispatch('loader/done')
    // },
    async createPdf(pdfs) {
      var merger = new PDFMerger();
      for (var receipt of pdfs) {
        await merger.add(receipt);
      }
      await merger.save('receipts');
    },
    async sendPdf() {
      var selectedReceipts = this.rowsSelected.map(m => m.receipt)
      var pdfs = []
      for (var receipt of selectedReceipts) {
        const existingPdfBytes = await fetch(receipt).then((res) => res.arrayBuffer())
        var bytes = new Uint8Array(existingPdfBytes);
        pdfs.push(bytes)
      }
      return pdfs
    },
    searchHandler() {
      if (this.searchKey != null){
        const result = this.data.filter((obj) =>
          obj.searchTerms.toLowerCase().includes(this.searchKey.toLowerCase())
        );
        this.dataTemp = result;
        // this.paginationChange++;
      }
    },

    filtersHandler() {
      let statusId;
      statusId = parseInt(this.getObjKey(this.statusSelected));
      this.getApplications(statusId);
    },
    getApplications(statusId) {
      Http()
        .post(ENDPOINTS.applications, {
          applicationId: this.typeId,
          statusId: this.showStatus ? statusId : null,
          dateFrom: new Date(this.dateFrom).getTime(),
          dateTo: new Date(this.dateTo).getTime(),
          page: this.perPage == null ? null : this.paginationChange - 1,
          length: this.perPage,
          includeReversed: this.includeReversed
        })
        .then((response) => {
          if (response.status) {
            this.data = response.data;
            this.data.forEach(element => {
              element["accountId"] = element.account.id
              element["accountName"] = element.account.name
              element["accountPhone"] = element.account.phone

              element.fields = element.fields.filter(f => f.hideFromAdmin == false)

              element.fields.forEach((el, index) => {
                var title = el.title.replace(/ *\([^)]*\) */g, "")
                var val = el.value
                var type = el.type
                var index = el.index
                var inputType = el.inputType

                if (type == "image") {
                  if (!this.findImgInArray(title, val)) {
                    var o = {
                      "id": element.id,
                      "title": title,
                      "value": val
                    }
                    this.imgs.push(o)
                  }
                } else {
                  element[title] = val
                  if (!this.findObjetInArray(title)) {
                    var obj;
                    if (inputType == "decimal" || inputType == "numeric") {
                      obj = {
                        "label": title,
                        "field": title,
                        "index": index,
                        filterOptions: {
                          enabled: true,
                          placeholder: "Search by " + title,
                          filterValue: "",
                        },
                        formatFn: this.formatNumber,
                      }
                    } else {
                      obj = {
                        "label": title,
                        "field": title,
                        "index": index,
                        filterOptions: {
                          enabled: true,
                          placeholder: "Search by " + title,
                          filterValue: "",
                        }
                      }

                    }
                    this.columnsExport.push(obj)
                  }
                }
              });
              if (this.isIskan && element.receipt != null) {
                var receiptTitle = "Iskan Receipt"
                var receiptValue = element.receipt;
                if (!this.findImgInArray(receiptTitle, receiptValue)) {
                  var o = {
                    "id": element.id,
                    "title": receiptTitle,
                    "value": receiptValue
                  }
                  this.imgs.push(o)
                }
              }
            });
            this.columnsExport.sort((a, b) => a.label == "Images" || b.label == "Images" ? 1 : a.index - b.index)
            this.dataTemp = this.data;
            this.addSearch(this.data);
            if (response.dialog != null && response.dialog.message != null ) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null ) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
    findObjetInArray(title){
      return this.columnsExport.find(f => f.field == title)!=undefined
    },
    findImgInArray(title, value){
      return this.imgs.find(f => f.title == title && f.value == value)!=undefined
    },
    formatNumber(value) {
      var intValue = parseFloat(value.replaceAll(",", ""))
      if (isNaN(intValue)){
        return value
      }
      return intValue.toLocaleString("en-US");
    },
    patchApplications(id, status) {
      Http()
        .patch(ENDPOINTS.applicationsPatch, {
          applicationSubmissionIdList: [id],
          statusId: status,
          typeId: this.typeId,
        })
        .then((response) => {
          if (response.status) {
            const indexOfObject = this.dataTemp.findIndex((object) => {
              return object.id === id;
            });

            if (this.statusSelected != "All") {
              this.dataTemp.splice(indexOfObject, 1);
            } else {
              this.dataTemp[indexOfObject].status = this.statusObj[status];
            }

            if (status == 4) {
              this.$toast.success("Application approved successfully");
            } else if (status == 5) {
              this.$toast.success("Application rejected successfully");
            }
            if (response.dialog != null && response.dialog.message != null  ) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null ) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },

    getObjKey(value) {
      return Object.keys(this.statusObj).find(
        (key) => this.statusObj[key] === value
      );
    },

    addSearch(data) {
      data.forEach(function (obj) {
        const searchArray = [];
        for (var key in obj) {
          if (typeof obj[key] != "object") {
            searchArray.push(obj[key]);
          } else {
            if (key == "account") {
              for (var key2 in obj[key]) {
                searchArray.push(obj[key][key2]);
              }
            } else if (key == "fields") {
              for (var key2 in obj[key]) {
                if (obj[key][key2].value && obj[key][key2].type !== "image") {
                  searchArray.push(obj[key][key2].value);
                }
              }
            }
          }
        }
        const searchString = searchArray.join(" ");
        obj["searchTerms"] = searchString;
      });
      this.dataTemp = this.data;
      this.searchHandler();
    },

    exportFunction() {
      const NewArray = [];
      this.dataTemp.forEach(function (obj) {
        const NewObj = {};

        for (var key in obj) {
          NewObj["applicationId"] = obj["id"];

          if (typeof obj[key] != "object") {
            NewObj[key] = obj[key];
          } else {
            if (key == "account") {
              if (NewObj["verificationDate"]) {
                NewObj["isVerified"] = "YES";
              } else {
                NewObj["isVerified"] = "NO";
              }
              for (var key2 in obj[key]) {
                NewObj[key2] = obj[key][key2];
              }
            } else if (key == "fields") {
              for (var key2 in obj[key]) {
                if (obj[key][key2].value && obj[key][key2].type !== "image") {
                  NewObj[obj[key][key2].title] = obj[key][key2].value;
                }
              }
            }
          }
        }

        NewArray.push(NewObj);
      });
      return NewArray;
    },

    editDepositorName(application, depositorName){
      Http()
        .post(ENDPOINTS.editDepositor, {
          newInvoice: depositorName,
          transactionId: application.transactionId
        })
        .then((response) => {
          if (response.status) {
            application["Current Depositor"] = depositorName;
            application.fields.filter(f => f.identifier == "currentDepositor")[0].value = depositorName
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
  },
};
</script>
<style>
.bg-blue-600 {
  background-color: #e40046 !important;
}

.bg-blue-500 {
  background-color: #e40046 !important;
}

.bg-blue-200 {
  background-color: #cf8ca0 !important;
}

.bg-blue-100 {
  background-color: #cf8ca0 !important;
}

.page-item .page-link {
  border-radius: 8px !important;
  margin: 4px;
  color: white !important;
  background-color: #a0aec0 !important;
  font-weight: bold !important;
  /* border-color: #E40046 !important; */
}

.page-item.active .page-link {
  background-color: #e40046 !important;
  border-color: #e40046;
  color: white !important;
  border-radius: 8px !important;
  margin: 4px;
}

.page-item {
  background-color: red !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.4;
}
.app .datetime-picker{
  width: 100%;
}
</style>
