import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import NotFound from '../views/404.vue'
import Payments from '../views/Payments.vue'
import ActiveAccounts from '../views/ActiveAccounts.vue'
import Collect from '../views/Collect.vue'
import Collections from '../views/Collections.vue'
import MainPage from '../views/MainPage.vue'
import Applications from '../views/AllApplications.vue'
import BalanceStatement from '../views/BalanceStatement.vue'
import Sales from '../views/Sales.vue'
import Statement from '../views/Statement.vue'
import CollectionApi from '../views/CollectionApiReport.vue'
import CreateCollectionApplication from '../views/CreateCollectionApplication.vue'
import OnlinePayments from '../views/OnlinePayments.vue'
import SchoolConfiguration from '../views/SchoolConfiguration.vue'
import UploadPaymentsConfigurations from '../views/UploadPayments/UploadPaymentsConfigurations.vue'
import UploadPayments from '../views/UploadPayments/UploadPayments.vue'
import UploadedPayments from '../views/UploadPayments/UploadedPayments.vue'
import ApplicationsPage from '../components/ApplicationsPage.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '*',
    name: 'MainPage',
    component: MainPage,
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
  },
  {
    path: '/collect',
    name: 'Collect',
    component: Collect,
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections,
  },

  {
    path: '/active-accounts',
    name: 'ActiveAccounts',
    component: ActiveAccounts,
  },
  {
    path: '/applications',
    name: 'Applications',
    component: Applications,
  },
  {
    path: '/application-submission',
    name: 'ApplicationsSubmission',
    component: ApplicationsPage,
    props: true 
  },
  {
    path: '/balance-statement',
    name: 'BalanceStatement',
    component: BalanceStatement,
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
  },
  {
    path: '/statement',
    name: 'Statement',
    component: Statement,
  },
  {
    path: '/collection-report',
    name: 'CollectionApiReport',
    component: CollectionApi,
  },
  {
    path: '/create-collection-application',
    name: 'CreateCollectionApplication',
    component: CreateCollectionApplication,
  },
  {
    path: '/online-payments',
    name: 'OnlinePayments',
    component: OnlinePayments,
  },
  {
    path: '/school-configuration',
    name: 'SchoolConfiguration',
    component: SchoolConfiguration,
  },
  {
    path: '/404',
    name: 'Not Found',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
