<template lang="">
  <div class="h-full items-stretch rounded-lg shadow-xl bg-white p-4 text-center">
    <!-- Card Header-->
    <header class="text-2xl font-extrabold py-4 px-4 text-center">
      {{ receivedData.id }}
    </header>

    <div class="">
      <ul class="text-black text-center font-semibold">
        <li>
          Account ID:
          <span class="text-gray-600"> {{ receivedData.account.id }}</span>
        </li>

        <li class="justify-center flex flex-row">
          Account Name:
          <span class="text-gray-600 uppercase">
            {{ receivedData.account.name }}</span
          >
          <BadgeCheckIcon
            class="text-green-600"
            v-if="receivedData.account.verificationDate"
          />
          <BadgeCheckIcon class="text-orange-600" v-else />
        </li>
        <li v-if="receivedData.account.phoneNumber && typeId == 54">
          Phone:
          <span class="text-gray-600">
            {{ receivedData.account.phoneNumber }}</span
          >
        </li>
        <li>
          Creation Date:
          <span class="text-gray-600">
            {{ this.receivedData.dateCreation }}
          </span>
        </li>
        <li v-if="showStatus">
          Application Status:
          <span v-if="receivedData.status == 'Approved'" class="text-green-600">
            {{ receivedData.status }}</span
          >
          <span
            v-else-if="receivedData.status == 'Rejected'"
            class="text-red-600"
          >
            {{ receivedData.status }}</span
          >
          <span v-else class="text-grey-600"> {{ receivedData.status }}</span>
        </li>
        <li
          v-for="field in receivedData.fields"
          v-bind:key="field.index"
          class=""
        >
          <template v-if="field.type == 'text'">
            {{ field.title }}:
            <span class="text-gray-600"> {{ field.value }}</span>
          </template>
        </li>
      </ul>
      <div class="flex flex-row"></div>
      <button
        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 mt-4 px-4 rounded inline-flex items-center "
        @click="viewImages()"
      >
        <PaperClipIcon />
        <span>Images</span>
      </button>
    </div>
    <!--Card Action Buttons-->
    <div v-if="this.$store.state.privileges.patchApplications && allowAcceptReject">
      <footer class="text-center py-3 px-8 text-gray-500">
        <button v-if="allowAcceptReject"
          :disabled="receivedData.status == 'Approved'"
          class="py-2 px-4 mt-2 mx-2 bg-woo rounded-lg text-white font-semibold"
          @click="$emit('patchApplication', receivedData.id, 4)"
        >
          Accept
        </button>
        <button v-if="allowAcceptReject"
          :disabled="receivedData.status == 'Rejected'"
          class="py-2 px-4 mt-2 mx-2 bg-gray-500 rounded-lg text-white font-semibold"
          @click="$emit('patchApplication', receivedData.id, 5)"
        >
          Reject
        </button>
        <button v-if="allowEditDepositor"
          class="py-2 px-4 mt-2 mx-2 bg-gray-500 rounded-lg text-white font-semibold"
          data-bs-toggle="modal" data-bs-target="#editDepositorModal"
        >
          Edit Depositor
        </button>
      </footer>
    </div>
    <viewImagesModal
      v-if="viewImagesModal"
      :images="images"
      @close="viewImagesModal = false"
    ></viewImagesModal>
    <div class="modal fade" id="editDepositorModal" tabindex="-1" aria-labelledby="editDepositorModal" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <label class="modal-title subTitle">Edit Depositor Name</label>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row text-left">
                <div class="col-6">
                  <div class="mb-3">
                    <label class="form-label">Depositor Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="depositorName" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary bg-woo" data-bs-dismiss="modal" @click="$emit('editDepositorName', receivedData, depositorName)">Create Service</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import viewImagesModal from "../components/ViewImagesModal.vue";
import { PaperClipIcon } from "@vue-hero-icons/outline";
import { BadgeCheckIcon } from "@vue-hero-icons/solid";

export default {
  methods: {

    viewImages() {
      this.images = [];

      this.receivedData.fields.forEach((element) => {
        if (element.type == "image") {
          this.images.push(element);
        }
      });
      this.images.sort(function (a, b) {
        return a.title.localeCompare(b.title);
      });

      this.viewImagesModal = true;
    },
  },
  data() {
    return {
      viewImagesModal: false,
      images: [],
      depositorName: null,
    };
  },
  components: {
    PaperClipIcon,
    viewImagesModal,
    BadgeCheckIcon,
  },

  created() {
    this.images = [];
  },
  props: {
    receivedData: Object,
    typeId: Number,
    appSubmissionId: Number,
    allowAcceptReject: Boolean,
    allowEditDepositor: Boolean,
    showStatus: Boolean,
  },
};
</script>
<style>
button:disabled {
  pointer-events: none !important;
  opacity: 0.2;
}
.subTitle{
  font-size: 23px;
  font-weight: 500;
}
</style>
