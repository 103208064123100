<template>
    <div v-if="applicationType != null">
        <div class="row">
          <div class="col-12">
            <label class="subTitle mb-3"><i class="bi bi-dash me-2"></i>Set Service</label>
          </div>
          <div class="col-6">
            <div class="mb-3 form-check">
              <input class="form-check-input" type="radio" name="setService" id="existingService" v-model="newService" :value="false" />
              <label class="form-check-label" for="existingService">Existing Service</label>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3 form-check">
              <input type="radio" name="setService" class="form-check-input" id="newService" v-model="newService" :value="true" data-bs-toggle="modal" data-bs-target="#newServiceModal"/>
              <label class="form-check-label" for="newService">New Service</label>
            </div>
          </div>
        </div>
        <div v-if="newService != null && !newService" class="row">
          <div class="col-4 d-inline-flex">
            <v-select :options="services" label="name" class="vselect w-100" placeholder="Choose Service" v-model="selectedService" :reduce="selectedService => selectedService.id" >
              <template slot="option" slot-scope="option" >
                  <span class="pl-4">{{ option.id + " - " + option.name }}</span>
              </template>
            </v-select>
            <button type="button" class="btn btn-secondary ms-1" @click="getServices('created', true)"><i class="bi bi-arrow-clockwise"></i></button>
          </div>
        </div>
        <div class="modal fade" id="newServiceModal" tabindex="-1" aria-labelledby="newServiceModal" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <label class="modal-title subTitle">Create Service</label>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-6">
                      <div class="mb-3">
                        <label class="form-label">Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="service.name" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" ref="closeServiceModal" data-bs-dismiss="modal" @click="newService = false">Close</button>
                <button type="button" class="btn btn-primary"  @click="createService">Create Service</button>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Http, { ENDPOINTS } from "../../request";
import {commonFunctions} from "../../helpers/CommonFunctions"
export default {
  components: {
    vSelect,
  },
  props: {
    applicationType: String,
    service: Object,
  },
  watch: {
    selectedService() {
      this.$emit('changeSelectedService', this.selectedService)
    },
  },
  data() {
    return {
      checkIfEmpty: commonFunctions.checkIfEmpty,
      newService: null,
      selectedService: null,
      services: null
    }
  },
  created() {
    this.getServices("created");
  },
  methods: {
    getServices(source, forceGet) {
      if (source == 'created' && (forceGet || this.services == null || this.services.length == 0)) {
        Http()
          .get(ENDPOINTS.servicesBeans, {
          })
          .then((response) => {
            if (response.status) {
              this.services = response.data;
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.success(response.dialog.message)
              }
            } else {
              if (response.dialog != null && response.dialog.message != null) {
                this.$toast.error(response.dialog.message)
              }
            }
          }).catch((e) => {
            setTimeout(this.getServices(source, true), 500);
          });
      }
      return this.services;
    },
    createService() {
      if (this.checkIfEmpty(this.service.name)) {
        this.$toast.error("Service Name is invalid")
        return;
      } 
      
      Http()
        .post(ENDPOINTS.createService, {
          name: this.service.name.toUpperCase()
        })
        .then((response) => {
          if (response.status) {
            this.service.id = response.data.serviceId
            this.services.push({
              "id":response.data.serviceId,
              "name":response.data.adminName
            });
            this.selectedService = response.data.serviceId
            this.newService = false
            this.$refs.closeServiceModal.click()
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.success(response.dialog.message)
            }
          } else {
            if (response.dialog != null && response.dialog.message != null) {
              this.$toast.error(response.dialog.message)
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Internal Server Error!")
        })
    },
  }
}
</script>
<style scoped>
.subTitle{
  font-size: 23px;
  font-weight: 500;
}

.btn-primary{
  background-color: #E40046!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
</style>